import styled from 'styled-components'
import mq from 'styles/mq'

const CIRCLE_DIAMETER = 10

export const Circle = styled.div`
  width: ${props =>
    props.width ? `${props.width}px` : `${CIRCLE_DIAMETER}px`};
  height: ${props =>
    props.width ? `${props.width}px` : `${CIRCLE_DIAMETER}px`};
  border-radius: ${props =>
    props.width ? `${props.width / 2}px` : `${CIRCLE_DIAMETER / 2}px`};
  background-color: #5dd886;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  ${mq.smallPhone`
    display:none;
  `};
  ${mq.phone`
    display:none;
  `};
  ${mq.tablet`
    display:flex;
    flex-direction:column;
    align-items:end;
  `};
`
