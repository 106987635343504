import React from 'react'
import { Trans } from '@lingui/macro'
import logo from 'assets/images/logos/mothership.png'
import congrats from 'assets/images/local-sun-confirmation.jpg'
import { PAYMENT_PROVIDERS } from 'config'

export const companyInfo = {
  name: 'Mothership',
  phone: '888-851-9373',
  termsLink:
    'https://static1.squarespace.com/static/618ade7d6061b346aa8b7614/t/63495b50ba654328f43388dc/1665751888320/Mothership+Terms+of+Use_20221013.pdf',
  privacyPolicyLink:
    ' https://static1.squarespace.com/static/618ade7d6061b346aa8b7614/t/63495b8a537d810e1e2f9e03/1665751946425/Mothership+Privacy+Policy_20221013.pdf',
  homepageLink: 'http://mothershipenergy.com',
  iosAppLink: 'https://itunes.apple.com/us',
  androidAppLink: 'https://play.app.goo.gl/',
  faviconLink: 'https://enroll.mothershipenergy.com/favicon.ico',
  forgotPasswordLink: 'https://myaccount.mothershipenergy.com/forgot-password',
  chaseMerchantID: '441193',
  chaseSubscriberID: '100000000325',
  chasePaymentCssURL:
    'https://frontend-chasepaymenttech.s3.us-west-2.amazonaws.com/form.css',
  paymentProvider: PAYMENT_PROVIDERS.CHASE,
  EFLLink: 'https://www.mothershipenergy.com/terms/',
  TOSYRACLink: 'https://www.mothershipenergy.com/terms/',
  customerPortalLink: 'https://myaccount.mothershipenergy.com/',
  callingHours: <Trans>(Mon - Fri 8am - 5pm CST)</Trans>,
  customerPortalName: 'My Account Portal',
  footerCertificate:
    'MI Texas REP 1, LLC dba Mothership | PUCT Certification No. 10298',
  supportEmail: 'care@mothershipenergy.com',

  // general (NTX) enrollment
  thankYouLink: '',
  thankYouLinkCommunitySolar: '',
}

const base = '#000000'
const primary = '#000000'
const secondary = '#5372AB'
const blue = '#00BCD4'
const gray = '#d5dee5'
const grayMedium = '#7D8D9E'
const grey = '#999999'
const green = '#7CA51B'
const greenPromo = '#8FAB36'
const error = '#dd2c00'
const neutral1 = '#022135'
const neutral2 = secondary // deviated from #7D8D9E
const neutral3 = secondary // deviated from #9DB1C6
const neutral4 = '#D5DEE5'
const neutral5 = '#F2F3F6'
const white = '#fff'
const footerColor = 'black'
const footerTextColor = '#fff'
const darkerPrimary = 'black' // used for hover effect
const background = 'white'
const bannerborderbottom = '#062539 1px solid'
const ratePanel = primary
const rateText = 'white'
const formField = '#5372AB'

export const color = {
  background,
  base,
  primary,
  secondary,
  blue,
  gray,
  grayMedium,
  grey,
  green,
  greenPromo,
  error,
  gradient: `linear-gradient(90deg, ${primary}, #f47725)`,
  link: primary,
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  white,
  footerColor,
  footerTextColor,
  darkerPrimary,
  transparent: '#ffffff00',
  containerBackground: background,
  bannerColor: `#fff`,
  bannerTextColor: secondary,
  bannerborderbottom,
  progressBarColor: primary,
  progressBarBackground: '#fff',
  buttonTextColor: '#fff',
  buttonColor: primary,
  slider: primary,
  dropdown: base,
  ratePanel,
  rateText,
  offerDetails: secondary,
  offerShortDescription: ratePanel,
  formField,
}

export const assets = { congrats }

export const logoConfig = {
  localPath: 'assets/images/logos/mothership.png',
  url: '',
  width: 'auto',
  height: '91px',
  padding: '10px',
  backgroundColor: 'transparent',
  useLocal: true,
  logo,
  progressBarImage: {
    custom: false,
  },
  header: {
    height: '100px',
  },
}

const baseFamily = 'europa-bold'
const customFont = 'europa-bold'

export const font = {
  extraBold: '800',
  semiBold: '600',
  medium: '500',
  normal: '400',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
}

export const fontFace = `
@font-face {
  font-family: ${font.customFont};
  src: url('/fonts/europa-bold/europa-bold.ttf');
};
`

export const features = {
  DISPLAY_REFERRAL_NAME: false,
  LOCALE_HEADER: true,
  CALL_HEADER: false,
  CALL_FOOTER: true,
  CALL_FOOTER_PHONE: true,
  CALL_FOOTER_EMAIL: true,
  REQUIRE_FULL_SOCIAL:
    process.env.REACT_APP_REQUIRE_FULL_SOCIAL === 'true' ? true : false,
}
