import {
  takeEvery,
  takeLatest,
  call,
  put,
  select,
  all,
} from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { push } from 'react-router-redux'
import * as storage from 'utils/storage'

import * as API from 'api'
import { LOGOUT, RENDERING_DASHBOARD } from 'modules/app/constants'

import { makeSelectUserID } from 'modules/user/selectors'

import { resetApp } from 'modules/app/actions'

import {
  getUserRequest,
  getUserFailure,
  getUserSuccess,
} from 'modules/user/actions'
import {
  getMembersRequest,
  getMembersSuccess,
  getMembersFailure,
  getEnrollmentRequest,
  getEnrollmentSuccess,
  getEnrollmentFailure,
  getMeterRequest,
  getMeterSuccess,
  getMeterFailure,
} from 'modules/members/actions'

import { selectIsGriddyGuest } from 'modules/members/selectors'

// eslint-disable-next-line

export function* getUserSaga({ userID }) {
  yield put(getUserRequest())
  try {
    const res = yield call(API.getUser, { userID })
    yield put(getUserSuccess(res))
    return res
  } catch (error) {
    yield put(getUserFailure(error.message))
    return null
  }
}

export function* getMembersSaga({ userID }) {
  yield put(getMembersRequest())
  try {
    const res = yield call(API.getMembers, { userID })
    const members = res.Members
    yield put(getMembersSuccess(members))
    return members
  } catch (error) {
    yield put(getMembersFailure(error.message))
    throw error
  }
}

export function* getBalance({ memberID }) {
  const account = yield call(API.getAccount, { memberID })
  const balance = {
    current: account.account_balance,
    pending: account.account_pending_balance,
    estTopOffDate: account.est_top_off_date,
  }

  return balance
}

export function* getMeterSaga({ memberID }) {
  yield put(getMeterRequest())
  try {
    const res = yield call(API.getMeter, { memberID })

    let meter = null
    if (res.meters.length) {
      meter = res.meters[0]
    }

    yield put(getMeterSuccess(meter))
    return meter
  } catch (error) {
    yield put(getMeterFailure(error.message))
    const { status } = error.response
    if (status >= 500) {
      throw error
    }
    return { error }
  }
}

export function* getEnrollmentSaga({ memberID }) {
  yield put(getEnrollmentRequest())
  try {
    const balance = yield call(getBalance, { memberID })
    const meter = yield call(getMeterSaga, { memberID })
    yield put(getEnrollmentSuccess({ memberID, balance, meter }))
    return { balance, meter }
  } catch (error) {
    yield put(getEnrollmentFailure({ memberID }))
    const { status } = error.response
    if (status >= 500) {
      throw error
    }
    return { error }
  }
}

export function* logoutSaga() {
  const isGriddyGuest = yield select(selectIsGriddyGuest)

  yield put(resetApp())
  storage.remove()

  if (isGriddyGuest) {
    yield put(push('/griddy-guest/login'))
    return
  }

  yield put(push('/login'))
}

export function* googleAnalyticsSaga(action, id) {
  let userID
  if (id) {
    userID = id
  } else {
    userID = yield select(makeSelectUserID())
  }
  try {
    while (!window.ga) {
      yield call(delay, 1000)
    }
    window.ga('set', 'dimension2', userID)
  } catch (err) {
    console.error(err)
  }
}

export default function* defaultSaga() {
  yield all([
    takeEvery(RENDERING_DASHBOARD, googleAnalyticsSaga),
    takeLatest(LOGOUT, logoutSaga),
  ])
}
