import * as storage from 'utils/storage'
import request from './request'
import { API_HOST, ITERABLE_URL, ITERABLE_KEY, EXIGO_API_HOST } from '../config'

export const api = async (endpoint, options, restricted) => {
  const url = `${API_HOST}${endpoint}`

  /*  For local server */
  // const host =
  //   endpoint === '/users/signin' ||
  //   endpoint === '/users'
  //   // ||
  //   // endpoint === '/enrollment/get_tdsps_and_offers' ||
  //   // endpoint === '/enrollment/meterLookup/search' ||
  //   // endpoint === '/enrollment/create_chase_payment_method'
  //     ? API_HOST
  //     : 'http://localhost:5000'
  // const url = `${host}${endpoint}`

  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })

  if (restricted) {
    headers.append('Authorization', `Bearer ${storage.getAccessToken()}`)
  }

  try {
    return await request(url, { ...options, headers })
  } catch (err) {
    if (err.code !== 16 || err.message !== 'token has expired') {
      return Promise.reject(err)
    }

    try {
      const { access_token: accessToken } = await request(
        `${API_HOST}/users/token/refresh`,
        {
          method: 'POST',
          body: JSON.stringify({ token: storage.getRefreshToken() }),
        }
      )

      storage.setAccessToken(accessToken)
      headers.set('Authorization', `Bearer ${accessToken}`)

      return request(url, { ...options, headers })
    } catch (err) {
      // Login component logs user out and resets app
      window.location.replace('/login')
      return
    }
  }
}

/* prettier-ignore */
api.post = (endpoint, restricted) => body => api(endpoint, { method: 'POST', body: JSON.stringify(body) }, restricted)

export const getAccount = api.post('/billing/account', true)
export const addFunds = api.post('/billing/addfunds', true)

export const getMeter = api.post('/meter/get_meter', true)
export const addMeter = api.post('/meter/meters', true)
export const updateSummerAutoLock = api.post(
  '/meter/update_summer_auto_lock',
  true
)

export const meterLookup = api.post('/enrollment/meterLookup/search')
export const validateMeterForEnrollment = api.post(
  '/enrollment/validate_meter_for_enrollment'
)

export const createMember = api.post('/members/create', true)
export const getMembers = api.post('/members/get_all', true)
export const updateMember = api.post('/members/update', true)

export const addCard = api.post('/payment/add_card', true)
export const enroll = api.post('/payment/enroll', true)

export const getPromo = api.post('/promo/get')
export const validateSalesChannel = api.post('/enrollment/validate/sales_channel_code')

export const getUser = api.post('/users', true)
export const forgotPasswordResetLink = api.post('/users/password/resetlink')
export const resetPassword = api.post('/users/password/reset')
export const login = api.post('/users/signin')
export const createUser = api.post('/users/signup')
export const updateUser = api.post('/users/update', true)
export const createChasePaymentMethod = api.post(
  '/enrollment/create_chase_payment_method'
)
export const createICheckPaymentMethod = api.post(
  '/enrollment/create_icheck_payment_method'
)
export const createStripePaymentMethod = api.post(
  '/enrollment/create_stripe_payment_method'
)
export const updateEnrollment = api.post('/enrollment/update')
export const uploadEnrollmentDocument = api.post('/enrollment/document/upload')
export const checkIfUserExist = api.post('/enrollment/userexists')
export const getTdspWithOffers = api.post('/enrollment/get_tdsps_and_offers')
export const getDeposit = api.post('/enrollment/getdeposit')
export const getChasePaymentUID = api.post('/enrollment/getchaseuid')
export const getRenewalOffers = api.post('/enrollment/get_renewal_offers')
export const markEmailVerified = api.post('/enrollment/mark_verified')
export const getEnrollment = api.post('/enrollment/get')
export const getRenewal = api.post('/enrollment/proton_renewal')
export const validateEnrollment = api.post('/enrollment/validate_enrollment')
export const getDocumentForSignature = api.post('/enrollment/document/sign')
export const getURLParams = api.post('/enrollment/url_params')
export const getEnrollmentSignatureUrl = api.post(
  '/enrollment/files/get_signature_upload_url'
)
export const getLMIProgramTypes = api.post('/community_solar/lmi_type/list')

export const getInAppMessages = userEmail =>
  request(
    `${ITERABLE_URL}inApp/getMessages?email=${userEmail}&count=10&platform=iOS&SDKVersion=None&api_key=${ITERABLE_KEY}`
  )

export const getGriddyProReferralInfo = code =>
  request(`${EXIGO_API_HOST}/pro/${code}`)

export const meterOverride = api.post('/enrollment/meter_options')
