import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Loader from 'components/Loader'

import { Button as StyledButton, LoaderContainer } from './styled'

const Link = StyledButton.withComponent(RouterLink)
const A = StyledButton.withComponent('a')

/* eslint-disable react/prop-types */
const Button = ({ loading, ...props }) => {
  if (loading) {
    return (
      <StyledButton {...props}>
        <LoaderContainer>
          <Loader color="#fff" size={props.secondary ? 6 : 10} />
        </LoaderContainer>
      </StyledButton>
    )
  }

  if (props.to) {
    return <Link {...props} />
  }

  if (props.href) {
    return <A target="_blank" rel="noopener noreferrer" {...props} />
  }

  return <StyledButton {...props} />
}

export default Button
