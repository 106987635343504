export const METER_LOOKUP_REQUEST = 'signup/METER_LOOKUP_REQUEST'
export const METER_LOOKUP_FAILURE = 'signup/METER_LOOKUP_FAILURE'
export const METER_LOOKUP_SUCCESS = 'signup/METER_LOOKUP_SUCCESS'

export const ENROLL_REQUEST = 'signup/ENROLL_REQUEST'
export const ENROLL_FAILURE = 'signup/ENROLL_FAILURE'
export const ENROLL_SUCCESS = 'signup/ENROLL_SUCCESS'

export const TDSP_OFFERS_SUCCESS = 'signup/TDSP_OFFERS_SUCCESS'
export const TDSP_OFFERS_FAILURE = 'signup/TDSP_OFFERS_FAILURE'

export const CREDIT_CHECK_FAILURE = 'signup/CREDIT_CHECK_FAILURE'
export const CREDIT_CHECK_SUCCESS = 'signup/CREDIT_CHECK_SUCCESS'

export const SELECT_OFFER = 'signup/SELECT_OFFER'
export const SUBMIT_LOGIN_FORM = 'login/SUBMIT_LOGIN_FORM'
export const SUBMIT_CREDIT_AUTH = 'login/SUBMIT_CREDIT_AUTH'
export const SUBMIT_SIGN_UP_FORM = 'signup/SUBMIT_SIGN_UP_FORM'
export const SUBMIT_VALIDATE_ENROLLMENT = 'signup/SUBMIT_VALIDATE_ENROLLMENT'
export const GET_DOCUMENT_FOR_SIGNATURE = 'signup/GET_DOCUMENT_FOR_SIGNATURE'
export const SUBMIT_SERVICE_ADDRESS_FORM = 'signup/SUBMIT_SERVICE_ADDRESS_FORM'
export const SUBMIT_SELECT_ADDRESS_FORM = 'signup/SUBMIT_SELECT_ADDRESS_FORM'
export const SUBMIT_SERVICE_INFO_FORM = 'signup/SUBMIT_SERVICE_INFO_FORM'
export const SUBMIT_PERSONAL_INFO_FORM = 'signup/SUBMIT_PERSONAL_INFO_FORM'
export const SUBMIT_PAYMENT_FORM = 'signup/SUBMIT_PAYMENT_FORM'
export const SUBMIT_PROMO_CODE = 'signup/SUBMIT_PROMO_CODE'
export const SUBMIT_UPDATE_REFERRAL_NAME = 'signup/SUBMIT_UPDATE_REFERRAL_NAME'
export const SUBMIT_ZIPCODE_FORM = 'signup/SUBMIT_ZIPCODE_FORM'
export const RESUBMIT_ZIPCODE_FORM = 'signup/RESUBMIT_ZIPCODE_FORM'
export const SUBMIT_CHASE_PAYMENT_CUSTOM_FORM =
  'signup/SUBMIT_CHASE_PAYMENT_CUSTOM_FORM'
export const SUBMIT_ICHECK_PAYMENT_CUSTOM_FORM =
  'SUBMIT_ICHECK_PAYMENT_CUSTOM_FORM'
export const SUBMIT_STRIPE_PAYMENT_CUSTOM_FORM =
  'SUBMIT_STRIPE_PAYMENT_CUSTOM_FORM'
export const SUBMIT_NO_PAYMENT_REQUIRED_FORM =
  'signup/SUBMIT_NO_PAYMENT_REQUIRED_FORM'
export const SUBMIT_GENERAL_SERVICE_ADDRESS_FORM =
  'signup/SUBMIT_GENERAL_SERVICE_ADDRESS_FORM'

export const SET_AUTO_PAY = 'signup/SET_AUTO_PAY'
export const SAVE_AGREEMENTS = 'signup/SAVE_AGREEMENTS'

export const SAVE_SERVICE_ADDRESS_VALUES = 'signup/SAVE_SERVICE_ADDRESS_VALUES'
export const SAVE_SELECT_ADDRESS_VALUES = 'signup/SAVE_SELECT_ADDRESS_VALUES'
export const SAVE_SELECTED_ADDRESS_VALUE = 'signup/SAVE_SELECTED_ADDRESS_VALUE'
export const SAVE_SWITCH_MOVE_VALUES = 'signup/SAVE_SWITCH_MOVE_VALUES'
export const SAVE_SERVICE_INFO_VALUES = 'signup/SAVE_SERVICE_INFO_VALUES'
export const SAVE_PERSONAL_INFO_VALUES = 'signup/SAVE_PERSONAL_INFO_VALUES'
export const SAVE_GENERAL_PERSONAL_INFO_VALUES =
  'signup/SAVE_GENERAL_PERSONAL_INFO_VALUES'
export const SAVE_START_DATE = 'signup/SAVE_START_DATE'
export const SAVE_GENERAL_SERVICE_ADDRESS_VALUES =
  'signup/SAVE_GENERAL_SERVICE_ADDRESS_VALUES'

export const ADD_CARD_FAILURE = 'signup/ADD_CARD_FAILURE'
export const ADD_CARD_SUCCESS = 'signup/ADD_CARD_SUCCESS'

export const ADD_FUNDS_FAILURE = 'signup/ADD_FUNDS_FAILURE'
export const ADD_FUNDS_SUCCESS = 'signup/ADD_FUNDS_SUCCESS'

export const ADD_METER_FAILURE = 'signup/ADD_METER_FAILURE'
export const ADD_METER_SUCCESS = 'signup/ADD_METER_SUCCESS'

export const POPULATE = 'signup/POPULATE'

export const SET_SHOULD_REDIRECT_TO_PAYMENT =
  'signup/SET_SHOULD_REDIRECT_TO_PAYMENT'

export const SAVE_REFERRAL_COOKIE_INFO = 'signup/SAVE_REFERRAL_COOKIE_INFO'
export const SAVE_REFERRAL_INFO = 'signup/SAVE_REFERRAL_INFO'
export const SAVE_PROMO_CODE = 'signup/SAVE_PROMO_CODE'
export const CHECK_OFFERS = 'signup/CHECK_OFFERS'
export const SAVE_SALES_CHANNEL = 'signup/SAVE_SALES_CHANNEL'
export const SAVE_PARAMETERS = 'signup/SAVE_PARAMETERS'
export const SUBMIT_PARAMETERS = 'signup/SUBMIT_PARAMETERS'

export const UPDATE_AUTO_SUMMER_LOCK = 'pricelock/UPDATE_AUTO_SUMMER_LOCK'
export const UPDATE_AUTO_SUMMER_LOCK_SUCCESS =
  'pricelock/UPDATE_AUTO_SUMMER_LOCK_SUCCESS'
export const UPDATE_AUTO_SUMMER_LOCK_FAILURE =
  'pricelock/UPDATE_AUTO_SUMMER_LOCK_FAILURE'
export const GET_GRIDDY_PRO_REFERRAL_INFO = `GET_GRIDDY_PRO_REFERRAL_INFO`

export const UPDATE_ENROLLMENT = 'enrollment/UPDATE_ENROLLMENT'
export const UPDATE_ENROLLMENT_SUCCESS = 'enrollment/UPDATE_ENROLLMENT_SUCCESS'
export const UPDATE_ENROLLMENT_FAILURE = 'enrollment/UPDATE_ENROLLMENT_FAILURE'
export const RETRIEVE_ENROLLMENT = 'enrollment/RETRIEVE_ENROLLMENT'
export const RETRIEVE_ENROLLMENT_SUCCESS =
  'enrollment/RETRIEVE_ENROLLMENT_SUCCESS'
export const VALIDATE_ENROLLMENT_SUCCESS =
  'enrollment/VALIDATE_ENROLLMENT_SUCCESS'
export const SUBMIT_GET_DOCUMENT_FOR_SIGNATURE_SUCCESS =
  'enrollment/SUBMIT_GET_DOCUMENT_FOR_SIGNATURE_SUCCESS'
export const RETRIEVE_ENROLLMENT_FAILURE = 'enrollment/GET_ENROLLMENT_FAILURE'
export const COMPLETE_ENROLLMENT = 'enrollment/COMPLETE_ENROLLMENT'
export const COMPLETE_ENROLLMENT_SUCCESS =
  'enrollment/COMPLETE_ENROLLMENT_SUCCESS'
export const COMPLETE_ENROLLMENT_FAILURE =
  'enrollment/COMPLETE_ENROLLMENT_FAILURE'
export const COMPLETE_ARCADIA_ENROLLMENT =
  'enrollment/COMPLETE_ARCADIA_ENROLLMENT'
export const PRODUCT_OFFER_SUCCESS = 'enrollment/PRODUCT_OFFER_SUCCESS'
export const PRODUCT_OFFER_FAILURE = 'enrollment/PRODUCT_OFFER_FAILURE'
export const SUBMIT_COMBINED_ENROLLMENT_FORM = 'enrollment/SUBMIT_COMBINED_ENROLLMENT_FORM'

export const USER_ALREADY_EXISTS = 'enrlloment/USER_ALREADY_EXISTS'
export const NOT_VALID_PRODUCT = `enrollment/NOT_VALID_PRODUCT`
export const METER_ALREADY_CONNECTED = `enrollment/METER_ALREADY_CONNECTED`
export const TOGGLE_IS_VALIDATING_ACCOUNT = `enrollment/TOGGLE_IS_VALIDATING_ACCOUNT`

export const DEPOSIT_REQUIREMENT = 'enrollment/DEPOSIT_REQUIREMENT'
export const WRONG_TDSP_ERROR = `enrollment/WRONG_TDSP_ERROR`
export const GET_USER_SUCCESS = `login/GET_USER_SUCCESS`
export const LOGIN_SUCCESS = `login/LOGIN_SUCCESS`

export const GET_CHASE_PAYMENT_UID = `enrollment/GET_CHASE_PAYMENT_UID`
export const GET_CHASE_PAYMENT_UID_SUCCESS = `enrollment/GET_CHASE_PAYMENT_UID_SUCCESS`
export const GET_CHASE_PAYMENT_UID_FAILURE = `enrollment/GET_CHASE_PAYMENT_UID_FAILURE`

export const VERIFY_EMAIL = `enrollment/VERIFY_EMAIL`
export const VERIFY_EMAIL_SUCCESS = `enrollment/VERIFY_EMAIL_SUCCESS`
export const VERIFY_EMAIL_FAILURE = `enrollment/VERIFY_EMAIL_FAILURE`

export const SET_ENROLLMENT_TYPE = 'enrollment/SET_ENROLLMENT_TYPE'
export const SUBMIT_VERIFY_EMAIL_FORM = 'enrollment/SUBMIT_VERIFY_EMAIL_FORM'
export const SUBMIT_RENEWAL_NO_PAYMENT_REQUIRED_FORM =
  'renewal/SUBMIT_RENEWAL_NO_PAYMENT_REQUIRED_FORM'
export const SUBMIT_RENEWAL_FORM = 'enrollment/SUBMIT_RENEWAL_FORM'
export const SET_VALIDATION_ERROR = 'enrollment/SET_VALIDATION_ERROR'

export const SUBMIT_COMMUNITY_SOLAR_ENROLLMENT_PAYMENT_FORM =
  'enrollment/SUBMIT_COMMUNITY_SOLAR_ENROLLMENT_PAYMENT_FORM'

export const CAPTURE_SIGNATURE = 'enrollment/CAPTURE_SIGNATURE'
export const SUBMIT_CAPTURE_SIGNATURE = 'enrollment/SUBMIT_CAPTURE_SIGNATURE'
export const SUBMIT_CAPTURE_SIGNATURE_SUCCESS = 'enrollment/SUBMIT_CAPTURE_SIGNATURE_SUCCESS'

export const GET_LMI_PROGRAM_TYPES = 'enrollment/GET_LMI_PROGRAM_TYPES'
export const GET_ENROLLMENT_FOR_DOCUMENT_SIGNING = 'enrollment/GET_ENROLLMENT_FOR_DOCUMENT_SIGNING'