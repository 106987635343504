import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'
import { init } from '@rematch/core'
import createRematchPersist from '@rematch/persist'
import selectPlugin from '@rematch/select'
import createHistory from 'history/createBrowserHistory'

import { RESET } from 'modules/app/constants'
import reducers from './reducers'
import createAnalyticsMiddleware from 'utils/analyticsMiddleware'

import { MIXPANEL_TOKEN } from './config'

export function configureStore(initialState = {}, history) {
  const sagaMiddleware = createSagaMiddleware()
  const analyticsMiddleware = createAnalyticsMiddleware({
    token: MIXPANEL_TOKEN,
  })

  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    analyticsMiddleware,
  ]

  const persistPlugin = createRematchPersist({
    key: 'app',
    whitelist: ['user', 'members', 'signUp'],
    version: 1,
  })

  const store = init({
    redux: {
      rootReducers: {
        [RESET]: ({ _persist, language, route }) => ({
          _persist,
          language,
          route,
        }),
      },
      reducers,
      initialState,
      middlewares,
    },
    
    plugins: [persistPlugin, selectPlugin()],
  })

  // Extensions
  store.runSaga = sagaMiddleware.run
  // Saga registry
  store.injectedSagas = {}

  return store
}

export const history = createHistory()
const store = configureStore({}, history)

export default store
