import FontFaceObserver from 'fontfaceobserver'
import { font as fontVariables } from 'styles/variables'

const font = new FontFaceObserver(fontVariables.customFont, {})

// When font is loaded, add a font-family using Open Sans to the body
font.load().then(
  () => {
    document.body.classList.add('fontLoaded')
  },
  () => {
    document.body.classList.remove('fontLoaded')
  }
)

if (fontVariables.customButtonFont) {
  const buttonFont = new FontFaceObserver(fontVariables.customButtonFont, {})

  buttonFont.load().then(
    () => {
      document.body.classList.add('buttonFontLoaded')
    },
    () => {
      document.body.classList.remove('buttonFontLoaded')
    }
  )
}
