import mq from 'styles/mq'
import styled, { css } from 'styled-components'
import { color } from 'styles/variables'

export const FooterContainer = styled.div`
  position: absolute;
  bottom: -218px;
  height: 218px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${color.footerColor};
  color: white;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: height 0.3s ease;

  ${mq.tablet`
  padding: 0px 120px;
`};
`

export const CallHeading = styled.span`
  color: white;
  font-size: 18px;
`

export const FooterLink = styled.a`
  ${props =>
    props.borderRight &&
    css`
      border-right: solid 1px white;
    `};
  font-size: 20;
  padding-right: 10px;
  padding-left: 10px;
  color: ${color.footerTextColor};
  &:link {
    color: ${color.footerTextColor};
    text-decoration: none;
  }
  &:visited {
    color: ${color.footerTextColor};
    text-decoration: none;
  }
`

export const LinksContainer = styled.div`
  width: 50%;
`
