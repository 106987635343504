import React from 'react'

import LinkButton from 'components/buttons/LinkButton'
import Logo from 'components/Logo'
import { features } from 'config/profile'

import { StyledHeader, HeaderBackground, LinkContainer, LockIcon } from './styled'

const LOCK_PATHS = ['/details', '/deposit']

const Header = () => {
  const isLocked = () => {
    return LOCK_PATHS.some((path) => window.location.pathname.includes(path))
  }

  return (
    <StyledHeader>
      <HeaderBackground>
        <div>
          <Logo />
        </div>
        <div>{isLocked() && <LockIcon />} </div>
        <div>
          <LinkContainer>
            {features.HEADER?.links &&
              features.HEADER.links.map((link, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <LinkButton key={index} href={link.link} variant="header" disabled={!link.link}>
                  {link.display}
                </LinkButton>
              ))}
          </LinkContainer>
        </div>
      </HeaderBackground>
    </StyledHeader>
  )
}

export default Header
