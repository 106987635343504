import * as griddy from './griddy'
import * as abacusEnergy from './abacusEnergy'
import * as shift from './shift'
import * as voltus from './voltus'
import * as walmart from './walmart'
import * as juice from './juice'
import * as flagshipPower from './flagship-power'
import * as localSun from './local-sun'
import * as think from './think'
import * as thinkNTX from './think-ntx'
import * as abundance from './abundance'
import * as thinkStripe from './think-stripe'
import * as mothership from './mothership'
import * as thinkV2 from './think-v2'
import * as branchEnergy from './branch-energy'
import * as fanfare from './fanfare'

// selected profile make selection based on command line arguments or build config
// eslint-disable-next-line import/no-mutable-exports
let selectedProfile

switch (process.env.REACT_APP_PROFILE_NAME) {
  case 'griddy': {
    selectedProfile = griddy
    break
  }
  case 'fanfare': {
    selectedProfile = fanfare
    break
  }
  case 'abacusEnergy': {
    selectedProfile = abacusEnergy
    break
  }
  case 'shift': {
    selectedProfile = shift
    break
  }

  case 'voltus': {
    selectedProfile = voltus
    break
  }

  case 'walmart': {
    selectedProfile = walmart
    break
  }

  case 'juice': {
    selectedProfile = juice
    break
  }

  case 'flagship-power': {
    selectedProfile = flagshipPower
    break
  }

  case 'local-sun': {
    selectedProfile = localSun
    break
  }

  case 'think': {
    selectedProfile = think
    break
  }

  case 'think-ntx': {
    selectedProfile = thinkNTX
    break
  }

  case 'think-v2': {
    selectedProfile = thinkV2
    break
  }

  case 'abundance': {
    selectedProfile = abundance
    break
  }

  case 'think-stripe': {
    selectedProfile = thinkStripe
    break
  }

  case 'mothership': {
    selectedProfile = mothership
    break
  }

  case 'branch-energy': {
    selectedProfile = branchEnergy
    break
  }

  case 'fanfare': {
    selectedProfile = fanfare
    break
  }

  default: {
    selectedProfile = griddy
  }
}

export const color = {
  ...selectedProfile.color,
}

export const theme = {
  ...selectedProfile.theme,
}

export const font = {
  ...selectedProfile.font,
  fontFace: selectedProfile.fontFace,
}

export const logoConfig = {
  ...selectedProfile.logoConfig,
}

export const companyInfo = {
  ...selectedProfile.companyInfo,
}

export const assets = {
  ...selectedProfile.assets,
}

export const features = {
  ...selectedProfile.features,
}

export const size = {
  ...selectedProfile.size,
}

export default selectedProfile
