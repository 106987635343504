import { createSelector } from 'reselect'
import { pathOr } from 'ramda'
import isEmpty from 'lodash.isempty'
import { initialState } from './reducer'
import { getAccessTokenFromLocalStorage } from 'utils/storage'

import { isMobileWebview } from 'utils/utils'

import {
  selectIsGriddyGuest,
  selectEnrolledMembers,
  selectCurrentMember,
} from '../members/selectors'

export const selectUserState = state => state.user

export const makeSelectUserID = () =>
  createSelector(selectUserState, substate =>
    pathOr(initialState.userID, ['userID'], substate)
  )

export const makeSelectEmail = () =>
  createSelector(selectUserState, substate =>
    pathOr(initialState.email, ['email'], substate)
  )

export const selectFormEmail = () =>
  createSelector(selectUserState, substate =>
    pathOr(initialState.formEmail, ['formEmail'], substate)
  )

export const selectPhone = createSelector(
  selectUserState,
  substate => substate.phone
)
export const selectEmail = createSelector(
  selectUserState,
  substate => substate.email
)
export const selectUserID = createSelector(
  selectUserState,
  substate => substate.userID
)
export const selectGGStatus = createSelector(
  selectUserState,
  substate => substate.gg_status
)

export const selectIsAuthenticated = createSelector(
  selectIsGriddyGuest,
  selectEnrolledMembers,
  selectCurrentMember,
  (isGriddyGuest, members, currentMember) =>
    isGriddyGuest ||
    (members.length > 0 && !isEmpty(currentMember)) ||
    (getAccessTokenFromLocalStorage() && isMobileWebview)
)

export default () => createSelector(selectUserState, substate => substate)
