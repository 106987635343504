import React from 'react'
import { Trans } from '@lingui/macro'
import logo from 'assets/images/logos/think-ntx.png'
import { PAYMENT_PROVIDERS } from 'config'
import backgroundImage from 'assets/images/stencil_background.png'

export const companyInfo = {
  name: 'Think',
  phone: '888-923-3633',
  termsLink: 'https://www.thinkenergy.com/terms/',
  privacyPolicyLink: 'https://www.thinkenergy.com/privacy-policy/',
  homepageLink: 'https://www.thinkenergy.com',
  iosAppLink: 'https://itunes.apple.com/us',
  androidAppLink: 'https://play.app.goo.gl/',
  faviconLink: 'https://enroll.thinkenergy.com/think.ico',
  forgotPasswordLink: 'https://myaccount.thinkenergy.com/forgot-password',
  chaseMerchantID: '460513',
  chaseSubscriberID: '100000000356',
  chasePaymentCssURL:
    'https://frontend-chasepaymenttech.s3.us-west-2.amazonaws.com/form.css',
  paymentProvider: PAYMENT_PROVIDERS.CHASE,
  EFLLink: 'https://think.com/terms/',
  TOSYRACLink: 'https://think.com/terms/',
  customerPortalLink: 'https://myaccount.thinkenergy.com/',
  callingHours: <Trans>(Mon - Fri 7am - 7pm CST)</Trans>,
  customerPortalName: 'MyAccount Portal',
  footerCertificate: 'Copyright 2022 • Think Energy LLC',
  supportEmail: 'care@thinkenergy.com',
  idVerificationEmail: 'ID_Verification@thinkenergy.com',
  idFailedLink: 'https://www.thinkenergy.com/id-verification/?fullName=&email=',
  idFailedLinkCommercial:
    'https://www.thinkenergy.com/small-comm/?fullName=&businessName=&email=',

  // general (NTX) enrollment
  thankYouLink: 'https://www.thinkenergy.com/confirmation',
  thankYouLinkCommunitySolar:
    'https://www.thinkenergy.com/cs-enrollment-confirmation/',
}

const base = '#25245D'
const primary = '#42DA80'
const secondary = '#25245D'
const blue = '#00BCD4'
const gray = '#E5E5E5'
const grayMedium = '#7D8D9E'
const grey = '#999999'
const green = '#7CA51B'
const greenPromo = '#8FAB36'
const error = '#dd2c00'
const neutral1 = '#022135'
const neutral2 = '#7D8D9E'
const neutral3 = '#9DB1C6'
const neutral4 = '#D5DEE5'
const neutral5 = '#F2F3F6'
const white = '#fff'
const footerColor = '#25245D'
const footerTextColor = 'white'
const darkerPrimary = '#2DBE68' // used for hover effect
const background = '#F3F3F3'
const bannerborderbottom = '#4C53E6 1px solid'
const ratePanel = '#25245D'
const rateText = '#42DA80'

export const color = {
  background,
  base,
  primary,
  secondary,
  blue,
  gray,
  grayMedium,
  grey,
  green,
  greenPromo,
  error,
  gradient: `linear-gradient(90deg, ${primary}, #f47725)`,
  link: secondary,
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  white,
  footerColor,
  footerTextColor,
  darkerPrimary,
  transparent: '#ffffff00',
  containerBackground: 'transparent',
  bannerColor: `white`,
  bannerTextColor: secondary,
  bannerborderbottom,
  progressBarColor: '#42DA80',
  progressBarBackground: '#fff',
  buttonTextColor: '#fff',
  buttonColor: primary,
  slider: primary,
  dropdown: base,
  ratePanel,
  rateText,
  offerDetails: secondary,
  offerShortDescription: ratePanel,
}

export const assets = {}

export const logoConfig = {
  localPath: 'assets/images/logos/think-ntx.png',
  url: '',
  width: 'auto',
  height: '85px',
  padding: '10px',
  backgroundColor: 'transparent',
  useLocal: true,
  logo,
  progressBarImage: {
    custom: true,
    name: 'think',
    height: '85px',
    width: 'auto',
    stepImageNames: {
      1: 'think-home',
      2: 'think-light',
      3: 'think-lock',
      4: 'think-card',
    },
  },
  header: {
    height: '100px',
    position: 'center',
  },
}

const baseFamily = 'Kanit-Regular'
const customFont = 'Kanit-Regular'

export const font = {
  extraBold: '800',
  semiBold: '600',
  medium: '500',
  normal: '400',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
}

export const fontFace = `
@font-face {
  font-family: ${font.customFont};
  src: url('/fonts/kanit/Kanit-Regular.ttf');
};
`

export const features = {
  DISPLAY_REFERRAL_NAME: true,
  BACKGROUND: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'bottom',
  },
  LOCALE_HEADER: false,
  CALL_HEADER: false,
  CALL_FOOTER: true,
  CALL_FOOTER_PHONE: true,
  CALL_FOOTER_EMAIL: true,
  REQUIRE_FULL_SOCIAL:
    process.env.REACT_APP_REQUIRE_FULL_SOCIAL === 'true' ? true : false,
}
