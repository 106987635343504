import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { Flex } from 'grid-styled'
import { FooterContainer, CallHeading, FooterLink } from './styled'
import PhoneContact from '../../../components/ContactGriddy/Phone'
import EmailContact from '../../../components/ContactGriddy/Email'
import { companyInfo, color, features } from 'config/profile'
import { Text } from 'components/text'
import { Trans } from '@lingui/macro'

const Footer = () => (
  <>
    <FooterContainer>
      <Flex
        width={1}
        flexDirection={['row', 'row', 'row', 'column']}
        alignItems={[
          'space-between',
          'space-between',
          'space-between',
          'center',
        ]}
        justifyContent="flex-end"
      >
        {features.CALL_FOOTER && (
          <Flex
            id="callContainer"
            flexDirection="column"
            width={[0.5, 0.5, 0.5, 1]}
            alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
          >
            <CallHeading>
              <Text color={color.footerTextColor}>
                <Trans>Contact us with your questions</Trans>
              </Text>
            </CallHeading>
            {features.CALL_FOOTER_PHONE && <PhoneContact />}
            {features.CALL_FOOTER_EMAIL && <EmailContact />}
            <Text extraSmall color={color.footerTextColor} mt={10}>
              {companyInfo.callingHours}
            </Text>
          </Flex>
        )}
        <Flex
          id="langAndInfoContainer"
          alignItems={['flex-end', 'flex-end', 'flex-end', 'center']}
          flexDirection="column"
          width={[0.5, 0.5, 0.5, 1]}
        >
          <Flex mt={15}>
            <FooterLink id="terms and conditions" borderRight>
              <Trans>All rights reserved</Trans>
            </FooterLink>
            <FooterLink
              href={companyInfo.termsLink}
              id="terms and conditions"
              target="_blank"
              borderRight
            >
              <Trans>Terms & Conditions</Trans>
            </FooterLink>
            <FooterLink
              href={companyInfo.privacyPolicyLink}
              id="terms and conditions"
              target="_blank"
            >
              <Trans>Privacy Policy</Trans>
            </FooterLink>
          </Flex>
          <Flex mt={15}>
            <FooterLink>{companyInfo.footerCertificate}</FooterLink>
          </Flex>
        </Flex>
      </Flex>
    </FooterContainer>
  </>
)

export default compose(withRouter)(Footer)
