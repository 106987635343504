import logo from 'assets/images/logos/shift.svg'
import { PAYMENT_PROVIDERS } from 'config'

export const companyInfo = {
  name: 'Shift',
  phone: '855-123-6207',
  termsLink: 'https://www.griddyx.com/legal/',
  privacyPolicyLink: 'https://www.griddy.com/privacy-policy',
  homepageLink: '',
  iosAppLink: 'https://itunes.apple.com/us/app/griddy/id1196099593?mt=8',
  androidAppLink:
    'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.app.griddy',
  faviconLink: 'https://www.enroll.development.griddyx.com/favicon.ico',
  forgotPasswordLink:
    'https://www.customer.development.griddyx.com/forgot-password',
  chaseMerchantID: '441193',
  chaseSubscriberID: '100000000325',
  chasePaymentCssURL:
    'https://frontend-chasepaymenttech.s3.us-west-2.amazonaws.com/form.css',
  paymentProvider: PAYMENT_PROVIDERS.CHASE,
  EFLLink: 'https://www.griddyx.com/legal/',
  TOSYRACLink: 'https://www.griddyx.com/legal/',
  customerPortalLink: 'https://demo-brookfield.d24eaz0jxwdq36.amplifyapp.com/',
  callingHours: '(Mon - Fri 8am - 5pm CST)',
  customerPortalName: 'My Shift Portal',
  footerCertificate: '',
  supportEmail: '',

  // general (NTX) enrollment
  thankYouLink: '',
  thankYouLinkCommunitySolar:'',
}

const primary = '#67FCAB'
const secondary = '#25245D'
const base = '#022135'
const gray = '#d5dee5'
const error = '#dd2c00'
const neutral2 = '#7D8D9E'
const neutral3 = '#9DB1C6'

export const color = {
  background: '#f2f3f6',
  base,
  primary,
  secondary,
  blue: '#5258FE',
  gray,
  grayMedium: '#7D8D9E',
  grey: '#999999',
  green: '#7CA51B',
  greenPromo: '#8FAB36',
  error,
  gradient: `linear-gradient(90deg, ${primary}, #f47725)`,
  link: primary,
  neutral1: secondary,
  neutral2,
  neutral4: '#D5DEE5',
  neutral5: '#F2F3F6',
  white: '#fff',
  footerColor: secondary,
  footerTextColor: '#fff',
  darkerPrimary: '#3ebf79',
  transparent: '#ffffff00',
  containerBackground: '#fdfbf8',
  bannerColor: '#fff',
  bannerTextColor: secondary,
  progressBarColor: primary,
  progressBarBackground: 'transparent',
  buttonTextColor: secondary,
  buttonColor: primary,
  slider: primary,
  dropdown: '#022135',
  ratePanel: secondary,
  rateText: primary,
  offerDetails: neutral3,
  offerShortDescription: neutral2,
}

export const assets = {}

export const logoConfig = {
  localPath: 'assets/images/logos/shift.svg',
  url: '',
  width: '160px',
  height: '70px',
  useLocal: true,
  logo,
  progressBarImage: {
    custom: false,
  },
}

const baseFamily = "'Helvetica Neue', Helvetica, Arial, sans-serif"
const customFont = 'Girloy'

export const font = {
  extraBold: '800',
  semiBold: '600',
  medium: '500',
  normal: '400',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
}

export const fontFace = `
@font-face {
  font-family: ${font.customFont};
  src:
    url('/fonts/gilroy/Gilroy-Regular.woff2') format('woff2'),
    url('/fonts/gilroy/Gilroy-Regular.woff') format('woff');
  font-weight: ${font.normal};
  font-style: normal;
}

@font-face {
  font-family: ${font.customFont};
  src:
    url('/fonts/gilroy/Gilroy-Medium.woff2') format('woff2'),
    url('/fonts/gilroy/Gilroy-Medium.woff') format('woff');
  font-weight: ${font.medium};
  font-style: normal;
}

@font-face {
  font-family: ${font.customFont};
  src:
    url('/fonts/gilroy/Gilroy-SemiBold.woff2') format('woff2'),
    url('/fonts/gilroy/Gilroy-SemiBold.woff') format('woff');
  font-weight: ${font.semiBold};
  font-style: normal;
}

@font-face {
  font-family: ${font.customFont};
  src:
    url('/fonts/gilroy/Gilroy-ExtraBold.woff2') format('woff2'),
    url('/fonts/gilroy/Gilroy-ExtraBold.woff') format('woff');
  font-weight: ${font.extraBold};
  font-style: normal;
}
`

export const features = {
  DISPLAY_REFERRAL_NAME: false,
  LOCALE_HEADER: true,
  CALL_HEADER: false,
  CALL_FOOTER: true,
  CALL_FOOTER_PHONE: true,
  CALL_FOOTER_EMAIL: true,
  REQUIRE_FULL_SOCIAL:
    process.env.REACT_APP_REQUIRE_FULL_SOCIAL === 'true' ? true : false,
}
