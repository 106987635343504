import React from 'react'
import { Circle, Wrapper } from './styled'
import { Text } from 'components/text'
import { Flex } from 'grid-styled'
import { companyInfo } from 'config/profile'

const OnlineSupportStatus = () => {
  return (
    <Wrapper>
      <Text medium>Question or Support?</Text>
      <Flex alignItems="center" ml={'7px'} width="100%" flexDirection="row">
        {/* GX-2392 temporarily disable online status */}
        {false && (
          <>
            <Circle />
            <Text ml={'5px'} style={{ whiteSpace: 'nowrap' }} small>
              Online Now&nbsp;
            </Text>{' '}
          </>
        )}

        <Text
          style={{ whiteSpace: 'nowrap' }}
          small
          color="#0EA250"
          fontWeight={600}
        >
          Call {companyInfo.phone}
        </Text>
      </Flex>
    </Wrapper>
  )
}

export default OnlineSupportStatus
