import styled, { css } from 'styled-components'
import { width, height, space, fontSize, background } from 'styled-system'

import { shadowBefore } from 'components/Shadow/styled'
import mq from 'styles/mq'
import { color, font, theme } from 'styles/variables'

const buttonTheme = theme.buttons || {}
const settings = {
  borderRadius: buttonTheme.borderRadius || '30px',
  shadowed: buttonTheme.shadowed || false,
  after: buttonTheme.after,
  lineHeight: buttonTheme.lineHeight || '2.6',
  colors: {
    primary: {
      color: color.buttonTextColor,
      backgroundColor: color.primary,
      borderColor: 'transparent',
      shadowColor: color.base,
      ...buttonTheme.primary,
      hover: {
        color: color.buttonTextColor,
        backgroundColor: color.darkerPrimary,
        shadowColor: color.base,
        ...buttonTheme.primary?.hover,
      },
      disabled: {
        backgroundColor: color.gray,
        ...buttonTheme.primary?.disabled,
      },
    },
    secondary: {
      color: color.buttonTextColor,
      backgroundColor: '#7d8d9e',
      borderColor: 'transparent',
      shadowColor: color.base,
      ...buttonTheme.secondary,
      hover: {
        color: color.buttonTextColor,
        backgroundColor: '#9db1c6',
        shadowColor: color.primary,
        ...buttonTheme.secondary?.hover,
      },
      disabled: {
        backgroundColor: color.gray,
        ...buttonTheme.secondary?.disabled,
      },
    },
  },
}

export const Button = styled.button`
  position: relative;
  cursor: pointer;
  outline: none;
  line-height: ${settings.lineHeight};
  text-decoration: none;
  font-size: ${font.sizes?.button?.phone || '1.8rem'};

  font-weight: ${font.button ? font.button : font.semiBold};
  font-family: ${font.customButtonFontFamily ? font.customButtonFontFamily : font.customFont};
  ${font.customButtonFontTransformations && `text-transform: ${font.customButtonFontTransformations};`}

  transition: all 0.3s ease;
  text-align: center;
  ${!font.sizes?.button && 'height: 50px'};
  display: inline-block;
  border-radius: ${settings.borderRadius};

  ${settings.after &&
  css`
    &::after {
      ${settings.after}
    }
  `}

  ${(props) => {
    const buttonProps = props.secondary ? settings.colors.secondary : settings.colors.primary

    return css`
      background: ${buttonProps.backgroundColor};
      color: ${props.color ? props.color : buttonProps.color};
      border: 1px solid ${buttonProps.borderColor};

      ${settings.shadowed &&
      css`
        &::before {
          ${shadowBefore}
          background-color: ${buttonProps.shadowColor};
        }
        margin-bottom: 10px;
      `}

      &:hover {
        background: ${buttonProps.hover.backgroundColor};
        color: ${buttonProps.hover.color};

        ${settings.shadowed &&
        css`
          &::before {
            background-color: ${buttonProps.hover.shadowColor};
          }
        `}
      }

      &:disabled,
      &[disabled] {
        cursor: default;

        ${buttonProps.disabled};
      }
    `
  }}

  

  ${mq.tablet`
    min-width: 140px;
    padding: 0 20px;
    font-size: ${font.sizes?.button?.tablet || '1.8rem'};
  `};

  ${width};
  ${height};
  ${space};
  ${fontSize};
`

export const LoaderContainer = styled.div`
  display: inline-block;
`

export default Button
