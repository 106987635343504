import React from 'react'
import { Trans } from '@lingui/macro'
import logo from 'assets/images/logos/abundance.png'
import { PAYMENT_PROVIDERS } from 'config'

export const companyInfo = {
  name: 'Abundance Energy',
  phone: '833-495-0127',
  termsLink: 'https://www.abundanceenergy.com/',
  privacyPolicyLink: 'https://www.abundanceenergy.com/',
  homepageLink: 'https://www.abundanceenergy.com',
  iosAppLink: 'https://itunes.apple.com/us',
  androidAppLink: 'https://play.app.goo.gl/',
  faviconLink: 'abundance.ico',
  forgotPasswordLink: 'https://my.abundanceenergy.com/forgot-password',
  chaseMerchantID: '458784',
  chaseSubscriberID: '100000000325',
  chasePaymentCssURL:
    'https://frontend-chasepaymenttech.s3.us-west-2.amazonaws.com/form.css',
  paymentProvider: PAYMENT_PROVIDERS.ICHECK,
  EFLLink: 'https://www.abundanceenergy.com/',
  TOSYRACLink: 'https://www.abundanceenergy.com/',
  customerPortalLink: 'https://my.abundanceenergy.com/',
  callingHours: <Trans>(Mon - Fri 8am - 5pm CST)</Trans>,
  customerPortalName: 'MyAccount Portal',  
  footerCertificate:
  'Abundance Energy | PUC Certificate 10314',
  supportEmail: 'care@abundanceenergy.com',
  noOffersPage_deprecated: 'https://abundanceenergy.com/lubbock/',
  // general (NTX) enrollment
  thankYouLink: 'https://abundanceenergy.com/order-confirmation',
  thankYouLinkCommunitySolar: 'https://www.abundanceenergy.com',
  confirmEmailPassword: true,
}

const base = '#000'
const primary = '#2e8df9'
const secondary = '#003f8f'
const blue = '#00BCD4'
const gray = '#d5dee5'
const grayMedium = '#7D8D9E'
const grey = '#999999'
const green = '#7CA51B'
const greenPromo = '#8FAB36'
const error = '#dd2c00'
const neutral1 = '#022135'
const neutral2 = '#7D8D9E'
const neutral3 = '#9DB1C6'
const neutral4 = '#D5DEE5'
const neutral5 = '#F2F3F6'
const white = '#fff'
const footerColor = secondary
const footerTextColor = '#fff'
const darkerPrimary = secondary // used for hover effect

export const color = {
  background: '#fff',
  base,
  primary,
  secondary,
  blue,
  gray,
  grayMedium,
  grey,
  green,
  greenPromo,
  error,
  gradient: `linear-gradient(90deg, ${primary}, #f47725)`,
  link: '#7cb5f9',
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  white,
  footerColor,
  footerTextColor,
  darkerPrimary,
  transparent: '#ffffff00',
  containerBackground: '#fff',
  bannerColor: white,
  bannerTextColor: secondary,
  progressBarColor: primary,
  progressBarBackground: 'transparent',
  buttonTextColor: '#fff',
  buttonColor: '#000',
  slider: '#000',
  dropdown: '#000',
  ratePanel: secondary,
  rateText: primary,
  offerDetails: neutral3,
  offerShortDescription: neutral2,
  containerBorder: '#000',
}

export const assets = {}

export const logoConfig = {
  localPath: 'assets/images/logos/flagship-power.png',
  url: '',
  width: 'auto',
  height: '80px',
  backgroundColor: 'transparent',
  useLocal: true,
  logo,
  progressBarImage: {
    custom: true,
    height: '130px',
    width: '130px',
    stepImageNames: {
      1: 'abundance_circle',
      2: 'abundance_circle',
      3: 'abundance_circle',
      4: 'abundance_circle',
    },
  },
}

const baseFamily = "'Poppins', sans-serif"
const customFont = "'Poppins', sans-serif"

export const font = {
  extraBold: '800',
  semiBold: '600',
  medium: '500',
  normal: '400',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
}

export const fontFace = `
@font-face {
    font-family: ${font.customFont};
    src: url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,500;0,600;1,100;1,400;1,500;1,600&display=swap');
  }
`

export const features = {
  DISPLAY_REFERRAL_NAME: false,
  LOCALE_HEADER: true,
  CALL_HEADER: false,
  CALL_FOOTER: true,
  CALL_FOOTER_PHONE: true,
  CALL_FOOTER_EMAIL: true,
  REQUIRE_FULL_SOCIAL:
    process.env.REACT_APP_REQUIRE_FULL_SOCIAL === 'true' ? true : false,
}
