import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'

import * as mixpanel from 'utils/mixpanel'
import { color } from 'styles/variables'
import { selectIsLangEnglish } from 'modules/language/selectors'
import { changeLocale } from 'modules/language/actions'

const StyledButton = styled.button`
  font-size: 1.6rem;
  color: ${color.link};
  ${styledSpace};
`

const LangButton = ({ isEnglish, changeLang, space }) => {
  const location = window.location
  return (
    <StyledButton
      data-testid="switchLangButton"
      onClick={() => {
        const nextLang = isEnglish ? 'es' : 'en'
        const currentLang = isEnglish ? 'en' : 'es'
        mixpanel.switchLang(location.pathname, currentLang, nextLang)
        changeLang(nextLang)
      }}
      {...space}
    >
      {isEnglish ? 'Español' : 'English'}
    </StyledButton>
  )
}

LangButton.propTypes = {
  isEnglish: PropTypes.bool.isRequired,
  changeLang: PropTypes.func.isRequired,
  space: PropTypes.object,
}

export default connect(
  createStructuredSelector({ isEnglish: selectIsLangEnglish }),
  { changeLang: changeLocale }
)(LangButton)
