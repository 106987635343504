import React from 'react'
import { Trans } from '@lingui/macro'
import logo from 'assets/images/logos/juice.png'
import { PAYMENT_PROVIDERS } from 'config'

export const companyInfo = {
  name: 'Juice Power',
  phone: '888-832-3596',
  termsLink: 'https://www.juicetx.com/terms-of-use/',
  privacyPolicyLink: 'https://www.juicetx.com/privacy-policy/',
  homepageLink: 'http://www.juicetx.com/',
  iosAppLink: 'https://itunes.apple.com/us',
  androidAppLink: 'https://play.app.goo.gl/',
  faviconLink: '',
  forgotPasswordLink: 'https://myaccount.juicetx.com/forgot-password',
  chaseMerchantID: '441193',
  chaseSubscriberID: '100000000325',
  chasePaymentCssURL:
    'https://frontend-chasepaymenttech.s3.us-west-2.amazonaws.com/form.css',
  paymentProvider: PAYMENT_PROVIDERS.CHASE,
  EFLLink: 'https://www.juicetx.com/terms-of-use/',
  TOSYRACLink: 'https://www.juicetx.com/terms-of-use/',
  customerPortalLink: 'https://myaccount.juicetx.com/',
  callingHours: <Trans>(Mon - Fri 8am - 5pm CST)</Trans>,
  customerPortalName: 'Juice Portal',
  footerCertificate: 'MI Texas REP 1, LLC dba JUICE | PUC Certificate 10298',
  supportEmail: '',

  // general (NTX) enrollment
  thankYouLink: '',
  thankYouLinkCommunitySolar:'',
}

const base = '#333e6b'
const primary = '#F37021'
const secondary = '#0F3762'
const blue = '#00BCD4'
const gray = '#d5dee5'
const grayMedium = '#7D8D9E'
const grey = '#999999'
const green = '#7CA51B'
const greenPromo = '#8FAB36'
const error = '#dd2c00'
const neutral1 = '#022135'
const neutral2 = '#7D8D9E'
const neutral3 = '#9DB1C6'
const neutral4 = '#D5DEE5'
const neutral5 = '#F2F3F6'
const white = '#fff'
const footerColor = '#fff'
const footerTextColor = '#333e6b'
const darkerPrimary = '#c75c1a' // used for hover effect

export const color = {
  background: '#fff',
  base,
  primary,
  secondary,
  blue,
  gray,
  grayMedium,
  grey,
  green,
  greenPromo,
  error,
  gradient: `linear-gradient(90deg, ${primary}, #f47725)`,
  link: primary,
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  white,
  footerColor,
  footerTextColor,
  darkerPrimary,
  transparent: '#ffffff00',
  containerBackground: '#fff',
  bannerColor: white,
  bannerTextColor: secondary,
  progressBarColor: primary,
  progressBarBackground: 'transparent',
  buttonTextColor: '#fff',
  buttonColor: primary,
  slider: primary,
  dropdown: base,
  ratePanel: secondary,
  rateText: primary,
  offerDetails: neutral3,
  offerShortDescription: neutral2,
  containerBorder: '#333e6b',
}

export const assets = {}

export const logoConfig = {
  localPath: 'assets/images/logos/juice.png',
  url: '',
  width: 'auto',
  height: '120px',
  backgroundColor: 'transparent',
  useLocal: true,
  logo,
  progressBarImage: {
    custom: false,
  },
}

const baseFamily = "'Poppins', sans-serif"
const customFont = "'Poppins', sans-serif"

export const font = {
  extraBold: '800',
  semiBold: '600',
  medium: '500',
  normal: '400',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
}

export const fontFace = `
@font-face {
    font-family: ${font.customFont};
    src: url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,500;0,600;1,100;1,400;1,500;1,600&display=swap');
  }
`

export const features = {
  DISPLAY_REFERRAL_NAME: false,
  LOCALE_HEADER: true,
  CALL_HEADER: false,
  CALL_FOOTER: false,
  CALL_FOOTER_PHONE: false,
  CALL_FOOTER_EMAIL: false,
  REQUIRE_FULL_SOCIAL:
    process.env.REACT_APP_REQUIRE_FULL_SOCIAL === 'true' ? true : false,
}
