export const API_HOST = process.env.REACT_APP_BASE_API_URL
export const CHASE_GETKEYS_URL = process.env.REACT_APP_CHASE_GETKEYS_URL
export const CHASE_GETKEYS_URL_GENERAL_ENROLLMENT = process.env.REACT_APP_CHASE_GETKEYS_NTX_URL
export const CHASE_ENRCRYPTION_URL = process.env.REACT_APP_CHASE_ENRCRYPTION_URL
export const CHASE_HOSTED_PAYMENT_URL = process.env.REACT_APP_CHASE_HOSTED_PAYMENT_URL

const allowedVerificationMethods = () => {
  const verDefault = ['email']
  if (process.env.REACT_APP_ALLOWED_VERIFICATION_METHODS) {
    const methods = process.env.REACT_APP_ALLOWED_VERIFICATION_METHODS.split(',').filter(x => x === 'sms' || x === 'email')
    return methods.length > 0 ? methods : verDefault
  }
  return verDefault
}

export const lplDuns = '0582138934100'

export const ALLOWED_VERIFICATION_METHODS = allowedVerificationMethods()

export const PAYMENT_PROVIDERS = {
  CHASE: 'CHASE',
  STRIPE: 'STRIPE',
  ICHECK: 'ICHECK',
}

export const isDev = !window.location.href.includes('enroll.griddy')
export const LOCAL_URL = 'http://localhost:5000'
export const NODE_ENV = process.env.NODE_EN
export const ACCEPTED_PAYMENT_CARD_TYPES = process.env.REACT_APP_ACCEPTED_PAYMENT_CARD_TYPES   
export const ACCEPTED_PAYMENT_CARD_TYPES_NTX = process.env.REACT_APP_ACCEPTED_PAYMENT_CARD_TYPES_NTX 
export const VGS_VAULT_ID = process.env.REACT_APP_VGS_VAULT_ID
export const VGS_ENVIRONMENT = process.env.REACT_APP_VGS_ENVIRONMENT

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY
export const SAASQUATCH_TENANT_ALIAS = isDev ? 'test_ai0zqe5id7tzt' : 'asxraqgny216e' // prettier-ignore
export const MIXPANEL_TOKEN = isDev ? 'f47534bbb4e2de0a93e08ed5a879dcc7' : '391711d9e880c672d701314d387b5254' // prettier-ignore
// Both keys are for dev sandbox to start with
export const ITERABLE_KEY = isDev ? '52dba399695e48679c7e5ebbb8e4af78' : '52dba399695e48679c7e5ebbb8e4af78' // prettier-ignore
export const ITERABLE_URL = 'https://api.iterable.com/api/' // prettier-ignore

export const EXIGO_API_HOST = isDev
  ? 'https://exigo.development.griddyx.com'
  : 'https://exigo.app.griddyx.com'

export const CHASE_PAYMENT_URL = isDev
  ? 'https://www.chasepaymentechhostedpay-var.com/hpf/1_1'
  : 'https://www.chasepaymentechhostedpay.com/hpf/1_1'


const parseAllowedDSCTReferralCodes = () => {
  const codeMap = {}
  const allowedCTCodes = process.env.REACT_APP_ALLOWED_DS_CT_REFERRALCODES || ""
  if ( allowedCTCodes ) {
    allowedCTCodes.split(",").forEach(code =>codeMap[code.toUpperCase()] = true);
  }  
  return codeMap;
}
  
export const ALLOWED_DS_CT_REFERRALCODES = parseAllowedDSCTReferralCodes()  
export const DS_REFERRAL_MAIN_PAGE_URL = process.env.REACT_APP_DS_REFERRAL_MAIN_PAGE_URL