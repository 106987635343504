import React from 'react'
import styled from 'styled-components'
import { companyInfo, color } from 'config/profile'

const A = styled.a`
  color: ${color.footerTextColor} !important;
`

const EmailContact = () => (
  <A href={`mailto:${companyInfo.supportEmail}`}>{companyInfo.supportEmail}</A>
)

export default EmailContact
