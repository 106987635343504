import styled from 'styled-components'
import mq from 'styles/mq'

export const Wrapper = styled.div`
  ${mq.smallPhone`
    display:none;
  `};
  ${mq.phone`
    display:flex;
    align-items:center;
    min-width:auto;
  `};
  ${mq.tablet`
    display:flex;
    align-items:center;
    min-width:auto;
  `};
  ${mq.desktop`
    display:flex;
    align-items:center;
    min-width:100%;
  `};
`
