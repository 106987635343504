export const GET_RENEWAL_OFFER_REQUEST = 'renewal_offer/RENEWAL_OFFER_REQUEST'
export const RENEWAL_OFFER_FAILURE = 'renewal_offer/RENEWAL_OFFER_FAILURE'
export const RENEWAL_OFFER_SUCCESS = 'renewal_offer/RENEWAL_OFFER_SUCCESS'

export const CONFIRM_RENEWAL_OFFER = 'renewal_offer/CONFIRM_RENEWAL_OFFER'
export const CONFIRM_RENEWAL_OFFER_SUCCESS =
  'renewal_offer/CONFIRM_RENEWAL_OFFER_SUCCESS'
export const CONFIRM_RENEWAL_OFFER_FAILURE =
  'renewal_offer/CONFIRM_RENEWAL_OFFER_FAILURE'

export const SELECT_OFFER = 'renewal_offer/SELECT_OFFER'
