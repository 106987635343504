import styled from 'styled-components'

import { logoConfig } from 'config/profile'
import mq from 'styles/mq'

const getLogo = () => {
  if (!logoConfig.useLocal) {
    return logoConfig.url
  }

  return logoConfig.logo
}

export const CompanyLogo = styled.img.attrs({
  src: getLogo(),
})`
  width: ${logoConfig.width};
  height: ${logoConfig.height};
  background: ${logoConfig.backgroundColor ? logoConfig.backgroundColor : '#fff'};
  padding: ${logoConfig.padding ? logoConfig.padding : '8px'};

  ${logoConfig.tablet &&
  mq.tablet`
    width: ${logoConfig.tablet.width || logoConfig.width};
    height: ${logoConfig.tablet.height || logoConfig.height};
  `};
`

export default CompanyLogo
