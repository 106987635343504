import { LOCATION_CHANGE } from 'react-router-redux'
import { merge } from 'ramda'

import globalReducers from 'modules/reducers'
import experimentReducer from 'modules/experiment/reducer'
import signUpReducer from 'pages/SignUp/reducer'
import renewalOfferReducer from 'pages/RenewalOffer/reducer'

const routeInitialState = {
  location: null,
}

const routeReducer = (state = routeInitialState, action) => {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return merge(state, {
        location: action.payload,
      })
    default:
      return state
  }
}

export default {
  route: routeReducer,
  signUp: signUpReducer,
  experiment: experimentReducer,
  renewalOffer: renewalOfferReducer,
  ...globalReducers,
}
