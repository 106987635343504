// All events are based on the following google sheet
// https://docs.google.com/spreadsheets/d/1IYg9rm2Y7pT8cbkXH1I4cNze9JyRKBUlyJ-s5XFQkjc/edit#gid=1510851395

import mixpanelBrowser from 'mixpanel-browser'
// import { DateTime } from 'luxon'
import { meterTypes } from 'api/constants'

// window.mixpanelMock will be provided from cypress e2e testing
const mixpanel = window.__mixpanel__ || mixpanelBrowser

const track = (...args) => mixpanel.track(...args)

const trackApi = (event, props) => track(event, { ...props, success: true })

export const init = token => mixpanel.init(token, { track_pageview: false })
export const reset = () => mixpanel.reset()
export const alias = id => mixpanel.alias(id)
export const identify = id => mixpanel.identify(id)
export const error = (event, message) => {
  mixpanel.track(event, { success: false, errorMessage: message })
}

const superProps = props => mixpanel.register(props)
const peopleProps = props => mixpanel.people.set(props)

const getPageByPathname = pathname => {
  if (!pathname) {
    return null
  }
  if (pathname === '/') {
    return 'wholesalePrice'
  }
  if (pathname.startsWith('/usage')) {
    return 'usage'
  }
  if (pathname.startsWith('/usage_v2')) {
    return 'usage-beta'
  }
  if (pathname.startsWith('/statement')) {
    return 'statement'
  }
  if (pathname.startsWith('/savings')) {
    return 'savings'
  }
  if (pathname.startsWith('/account')) {
    return 'account'
  }
  if (pathname.startsWith('/help')) {
    return 'support'
  }
  return null
}



const pathNames = {
  '/login': 'Login',
  '/sign-up': 'Become a Member',
  '/sign-up/service-address': 'Service Address',
  '/sign-up/select-address': 'Select Address',
  '/sign-up/service': 'Service Information',
  '/sign-up/not-available': 'Meter Not Supported',
  '/sign-up/personal': 'Personal Information',
  '/sign-up/business': 'Business Members',
  '/sign-up/payment': 'Payment Details',
  '/sign-up/thank-you': 'Congratulations!',
  '/': 'Wholesale Price',
  '/usage-hourly': 'Usage-Hourly',
  '/usage-daily': 'Usage-Daily',
  '/usage-monthly': 'Usage-Monthly',
  '/statement-breakdown': 'Statement',
  '/account': 'Account',
  '/account/payment': 'Payment Method',
  '/account/add-funds': 'Add Funds',
  '/account/transaction-history': 'Transaction History',
  '/help': 'Support',
  '/account/meter-summary': 'Meter Summary',
  '/add-meter': 'Add Additional Meter',
  '/add-meter/service-address': 'MM: Service Address',
  '/add-meter/select-address': 'MM: Select Address',
  '/add-meter/service': 'MM: Service Info',
  '/add-meter/business': 'MM: Business members',
  '/add-meter/payment': 'MM: Payment Details',
  '/add-meter/not-available': 'MM: Meter Not Supported',
  '/add-meter/thank-you': 'MM: Success!',
  '/savings/business': 'Bus Savings',
  '/savings/residential': 'Resi Savings',
  '/savings/residential-prologue': 'Resi Savings Prologue',
  '/griddy-guest/sign-up': 'Be Our Guest',
  '/griddy-guest/login': 'Login',
  '/enroll': 'Finish Enrolling',
  '/enroll/service-address': 'Service Address',
  '/enroll/select-address': 'Select Address',
  '/enroll/service': 'Service Info',
  '/enroll/personal': 'Personal Information',
  '/enroll/business': 'Business Members',
  '/enroll/payment': 'Payment Details',
  '/enroll/thank-you': 'Congratulations!',
  '/usage_v2': 'usage-beta',
  '/rates': 'We Are In Your Area',
  '/not-in-area': 'We Are Not In Your Area',
}

const getPageName = (pathname, lang) => {
  const pageName = pathNames[pathname]

  if (!pageName) {
    return null
  }

  if (lang === 'es') {
    if (pageName.startsWith('MM:')) {
      return `ES-${pageName}`
    }
    if (
      pathname.startsWith('/login') ||
      pathname.startsWith('/sign-up') ||
      pathname.startsWith('/add-meter')
    ) {
      return `ES: ${pageName}`
    }
  }

  return pageName
}

export const pageView = ({ pathname }, lang, experiment) => {
  const pageName = getPageName(pathname, lang)

  if (pageName) {
    track('pageView', { pageName, experiment })
  }
}

export const experimentDecision = experiment => {
  superProps({
    experiment,
  })

  peopleProps({
    experiment,
  })

  track('bannerExperiment', { experiment })
}

export const createUser = user => {
  superProps({
    email: user.email,
    ggStatus: user.gg_status,
  })
  peopleProps({
    userID: user.userID,
    ggStatus: user.gg_status,
    $email: user.email,
    $created: new Date().toISOString(),
  })
  trackApi('createUser')
}

export const createMember = member => {
  const common = {
    memberCreated: member.created_date,
    DOB: member.birthday,
  }
  const firstName = member.first_name
  const lastName = member.last_name
  superProps({
    ...common,
    firstName,
    lastName,
  })
  peopleProps({
    memberID: member.memberID,
    ...common,
    $first_name: firstName,
    $last_name: lastName,
    $name: `${firstName} ${lastName}`,
  })
  trackApi('createMember')
}

export const userGet = ({ userID, email, phone, gg_status }) => {
  superProps({
    email,
    phone,
    ggStatus: gg_status,
  })
  peopleProps({
    userID,
    $email: email,
    $phone: phone,
    ggStatus: gg_status,
  })
}

export const createGuest = ({ memberID, ggStatus }) => {
  const guestCreated = new Date().toISOString()
  superProps({ guestCreated, ggStatus })
  peopleProps({ memberID, guestCreated, ggStatus })
  track('createGuest')
}

export const signIn = ({ ggStatus }) => {
  superProps({ ggStatus })
  peopleProps({ ggStatus })
}

export const createMemberMM = member => {
  const props = {
    memberCreated: new Date().toISOString(),
    memberID: member.memberID,
  }
  trackApi('createMemberMM', props)
}

const memberUpdateMM = memberID => {
  trackApi('memberUpdateMM', { memberID })
}

export const memberUpdate = (
  { memberID, birthday, first_name, last_name },
  updateType
) => {
  if (updateType === 'addMeter') {
    memberUpdateMM(memberID)
    return
  }

  superProps({ DOB: birthday, firstName: first_name, lastName: last_name })
  peopleProps({
    DOB: birthday,
    $first_name: first_name,
    $last_name: last_name,
    $name: `${first_name} ${last_name}`,
  })
  trackApi('memberUpdate', { memberID })
}

export const signupComplete = ({
  isAddMeter,
  memberID,
  requestType,
  loadZone,
  meterCity,
  meterZipcode,
  requestedStartDate,
  polrRateClass,
  numberOfMeters,
}) => {
  const event = isAddMeter ? 'signupCompleteMM' : 'signupComplete'

  if (isAddMeter) {
    peopleProps({ numberOfMeters })
  } else {
    superProps({ loadZone, meterCity, meterZipcode, polrRateClass })
    peopleProps({
      memberID,
      loadZone,
      meterCity,
      meterZipcode,
      requestedStartDate,
      polrRateClass,
    })
  }

  trackApi(event, {
    memberID,
    requestType,
    loadZone,
    meterCity,
    meterZipcode,
    requestedStartDate,
    polrRateClass,
  })
}

export const toolTipTypes = {
  currentCost: 'currentCost',
  projectedCost: 'projectedCost',
  electricityUsage: 'electricityUsage',
  pricePerkWh: 'pricePerkWh',
  noMarkupCost: 'noMarkupCost',
  savingsLearnMore: 'savingsLearnMore',
  estRechargeDate: 'estRechargeDate',
}

export const toolTip = type => track('toolTip', { type })

export const usageBarClick = (type, usageDate) =>
  track('usageBarClick', { type, usageDate })

export const projectedCostScroll = () => track('projectedCostScroll')

export const unsupportedMeter = errorType => {
  const type = {
    [meterTypes.OUT_OF_AREA]: 'outOfArea',
    [meterTypes.NO_METER]: 'unmetered',
    [meterTypes.LARGE_COMMERCIAL]: 'largeCommercial',
    [meterTypes.BLANK_TDSP_AMS]: 'unsupportedType',
    [meterTypes.SWITCH_HOLD]: 'switchHold',
  }[errorType]

  track('unsupportedMeter', {
    type,
  })
}

export const addFunds = ({
  amount,
  accountBalance,
  paymentResponse,
  isPrimaryCard,
}) =>
  track('addFunds', { amount, accountBalance, paymentResponse, isPrimaryCard })

export const clickAddNewCard = ({ type }) => track('clickAddNewCard', { type })

export const switchMeter = ({ meterName, numberOfMeters, griddyRateClass }) => {
  superProps({ meterName, numberOfMeters, griddyRateClass })
  peopleProps({ numberOfMeters })
  track('switchMeter', { meterName })
}

export const expandLineItems = type => track('expandLineItems', { type })

export const statementDropdown = date =>
  track('statementDropdown', { month: date })

export const updateRecharge = ({ recharge_dollars }) => {
  const props = { rechargeAmount: recharge_dollars }
  superProps(props)
  peopleProps(props)
  track('updateRecharge')
}

export const userUpdate = ({ email, phone, new_password }) => {
  if (email) {
    superProps({ email })
    peopleProps({ $email: email })
    trackApi('userUpdate', { type: 'email' })
  }

  if (phone) {
    superProps({ phone })
    peopleProps({ $phone: phone })
    trackApi('userUpdate', { type: 'phone' })
  }

  if (new_password) {
    trackApi('userUpdate', { type: 'password' })
  }
}

export const dontSeeMyAddress = () => track('dontSeeMyAddress')

export const clickAddMeter = type => track('clickAddMeter', { type })

export const clickMeterSummary = type => track('clickMeterSummary', { type })

export const clickAddFunds = type => track('clickAddFunds', { type })

export const clickMoreTransactions = () => track('clickMoreTransactions')

export const switchLang = (pathname, currentLang, nextLang) => {
  const pageName = getPageName(pathname, currentLang)
  const nextPageName = getPageName(pathname, nextLang)

  track('switchLang', { language: nextLang, pageName }, () => {
    track('pageView', { pageName: nextPageName })
  })
}

export const switchRates = (pathname, nextView) => {
  const pageName = getPageName(pathname)

  track('switchRates', { rateView: nextView, pageName })
}

export const downloadApp = () => track('downloadApp')

export const clickDownloadApp = pathname => {
  const page = getPageByPathname(pathname)
  track('clickDownloadApp', { page })
}

export const supExport = supMonth => track('supExport', { supMonth })

export const clickChangeRecharge = type =>
  track('clickChangeRecharge', { type })

// Event only for native mobile apps
// export const ratingDialog = no op
// export const savingsGraphScroll = no op

export const savingsDetails = ({ type, savings, totalSavings, month }) =>
  track('savingsDetails', {
    type,
    savings,
    totalSavings,
    month,
  })

export const memberSet = member => {
  superProps({
    DOB: member.birthday,
    firstName: member.first_name,
    lastName: member.last_name,
    memberCreated: member.created_date,
    rechargeAmount: member.recharge_dollars,
  })
  peopleProps({
    DOB: member.birthday,
    $first_name: member.first_name,
    $last_name: member.last_name,
    memberCreated: member.created_date,
    $name: `${member.first_name} ${member.last_name}`,
  })
}



export const updateZipcode = ({ pathname, zipcode }) => {
  const page = getPageByPathname(pathname)
  superProps({ zipcode })
  peopleProps({ zipcode })
  track('updateZipcode', { page, zipcode })
}

export const updatePreferedLanguage = languagePreference => {
  superProps({ languagePreference })
  peopleProps({ languagePreference })
}

export const outOfAreaModal = ({ type }) => track('outOfAreaModal', { type })

export const updateContractExp = ({ contractExpDate }) => {
  superProps({ contractExpDate })
  peopleProps({ contractExpDate })
  track('updateContractExp', { contractExpDate })
}

export const updateHomeSize = ({ pathname, size }) => {
  const page = getPageByPathname(pathname)
  superProps({ homeSize: size })
  peopleProps({ homeSize: size })
  track('updateHomeSize', { page, size })
}

export const clickFinishEnrolling = pathname => {
  const page = getPageByPathname(pathname)
  track('clickFinishEnrolling', { page })
}

export const clickContractExp = ({ type }) =>
  track('clickContractExp', { type })

export const clickChangeZip = ({ type }) => track('clickChangeZip', { type })

export const clickChangeHomeSize = ({ type }) =>
  track('clickChangeHomeSize', { type })

export const clickLogIn = ({ page }) => track('clickLogIn', { page })

const getGraphTypeName = graphType => {
  switch (graphType) {
    case 'usage_kwh':
      return 'usage'
    case 'cost_dollars':
      return 'cost'
    case 'price_ckwh':
      return 'price'
    default:
      return ''
  }
}

const getViewTypeName = viewType => {
  switch (viewType) {
    case 'daily':
      return 'day'
    case 'monthly':
      return 'month'
    case 'yearly':
      return 'year'
    default:
      return ''
  }
}

const getPlatformName = platform => {
  if (platform === 'Android' || platform === 'iOS') {
    return platform
  }
  return 'web'
}

export const clickUsageGraphType = ({
  graphType,
  viewType,
  platform,
  usagePopUp,
}) => {
  trackApi('clickUsageGraphType', {
    graphType: getGraphTypeName(graphType),
    viewType: getViewTypeName(viewType),
    platform: getPlatformName(platform),
    usagePopUp,
  })
}

export const clickUsageDateNav = ({
  navType,
  viewType,
  usageDate,
  platform,
}) => {
  trackApi('clickUsageDateNav', {
    navigationType: navType,
    viewType: getViewTypeName(viewType),
    platform: getPlatformName(platform),
    usageDate,
  })
}

export const clickUsageViewType = ({ viewType, platform }) => {
  trackApi('clickUsageViewType', {
    viewType: getViewTypeName(viewType),
    platform: getPlatformName(platform),
  })
}

export const clickUsagePopup = ({
  graphType,
  viewType,
  usageDate,
  platform,
}) => {
  trackApi('clickUsagePopup', {
    graphType: getGraphTypeName(graphType),
    viewType: getViewTypeName(viewType),
    platform: getPlatformName(platform),
    usageDate,
  })
}

export const scrollUsageGraph = ({ graphType, viewType, platform }) => {
  trackApi('scrollUsageGraph', {
    graphType: getGraphTypeName(graphType),
    viewType: getViewTypeName(viewType),
    platform: getPlatformName(platform),
  })
}

export const clickUsageGraph = ({ graphType, viewType, platform }) => {
  trackApi('clickUsageGraph', {
    graphType: getGraphTypeName(graphType),
    viewType: getViewTypeName(viewType),
    platform: getPlatformName(platform),
  })
}
