export default number => {
  if (!number) return ''
  number = number.replace(/[+1]/, '')
  return `(${number.slice(1, 4)}) ${number.slice(4, 7)}-${number.slice(7, 11)}`
}

export const removePhoneMask = number => number && number.replace(/[^\d]/g, '')
export const phoneFieldX = {
  placeholder: 'XXX-XXX-XXXX',
  //prettier-ignore
  mask: [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
}
