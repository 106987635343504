import React from 'react'

import { companyInfo } from 'config/profile'

import Logo from './styled'

const LinkLogo = () => (
  <a href={companyInfo.homepageLink} target="_blank" rel="noopener noreferrer">
    <Logo />
  </a>
)

export default LinkLogo
