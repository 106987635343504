import { DateTime } from 'luxon'
import { Trans } from '@lingui/macro'

const DEFAULT_TZ = 'America/Chicago'

export const daysOfTheWeek = {
  0: <Trans>Sun</Trans>,
  1: <Trans>Mon</Trans>,
  2: <Trans>Tues</Trans>,
  3: <Trans>Wed</Trans>,
  4: <Trans>Thurs</Trans>,
  5: <Trans>Fri</Trans>,
  6: <Trans>Sat</Trans>,
}

export const parseDate = date => {
  if (!date) {
    return DateTime.local().setZone('America/Chicago')
  }

  if (date.isValid) {
    return date
  }

  if (typeof date === 'string') {
    if (date.split(' ').length > 1) {
      return DateTime.fromHTTP(date)
    }

    return DateTime.fromISO(date)
  }

  return DateTime.fromJSDate(date)
}

export function formatDate(date, formatString = 'yyyy-LL-dd') {
  return parseDate(date).toFormat(formatString)
}

export function getDisplayFormatFromString(dateStr) {
  try {
    const dateArr = dateStr.split('T')[0].split('-')
    return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`
  } catch (err) {
    return dateStr
  }
}

export function getStandardDisplayFormatFromString(dateStr) {
  try {
    const dateArr = dateStr.split('T')
    return dateArr[0]
  } catch (err) {
    return dateStr
  }
}

export function dateTZ(date = DateTime.local(), tz = DEFAULT_TZ) {
  return parseDate(date).setZone(tz)
}

export const localeDateFormat = locale => (locale === 'es' ? "dd'/'MM'/'yy" : "MM'/'dd'/'yy")
