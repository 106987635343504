import React, { useEffect, useState } from 'react'
import { Wrapper } from './styled'
import { breakpoints } from 'styles/mq'
import { selectLocale } from 'modules/language/selectors'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

const DATA_BUSINESSUNIT_ID = '63e65d0c89beef73c3cb59ab' // public read only

const EXTRASMALL = 'smallPhone' // microButton
const SMALL = 'phone' // microCombo
const MEDIUM = 'tablet' // microStar
const LARGE = 'desktop' // mini

const WIDGETS = {
  [LARGE]: {
    breakpoint: LARGE, // internal
    'data-template-id': '5419b6ffb0d04a076446a9af',
    'data-style-height': '20px',
    'data-style-width': '100%',
    'data-theme': 'light',
  },
  [MEDIUM]: {
    breakpoint: MEDIUM, // internal
    'data-template-id': '5419b732fbfb950b10de65e5',
    'data-style-height': '24px',
    'data-style-width': '100%',
    'data-theme': 'light',
  },
  [SMALL]: {
    breakpoint: SMALL, // internal
    'data-template-id': '53aa8807dec7e10d38f59f32',
    'data-style-height': '100px',
    'data-style-width': '100px',
  },
  [EXTRASMALL]: {
    breakpoint: EXTRASMALL, // internal
    'data-template-id': '5419b757fa0340045cd0c938',
    'data-style-height': '25px',
    'data-style-width': '100%',
  },
}

const getTrustPilotWidgetBySize = ({ size = SMALL, locale = 'en' }) => {
  const dataLocale = `${locale}-US`

  const baseConfig = {
    'data-locale': dataLocale,
    'data-businessunit-id': DATA_BUSINESSUNIT_ID,
  }

  let config = WIDGETS[size]

  if (config === undefined) {
    // default
    return {
      ...baseConfig,
      ...WIDGETS[SMALL],
      breakpoint: size,
    }
  }
  return {
    ...baseConfig,
    ...config,
  }
}

const TrustPilotRating = ({ locale }) => {
  const ref = React.useRef(null)

  const [widgetConfig, setWidgetConfig] = useState(
    getTrustPilotWidgetBySize({ locale: locale })
  )

  useEffect(() => {
    // TrustPilot CDN is injected in public/index.html
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [window.Trustpilot, widgetConfig])

  useEffect(() => {
    // update on locale change only
    setWidgetConfig(
      getTrustPilotWidgetBySize({
        size: widgetConfig.breakpoint,
        locale: locale,
      })
    )
  }, [locale])

  useEffect(() => {
    // update widget on screen resize
    const updateWidgetOnScreenSize = () => {
      const currentBreakpoint = Object.keys(breakpoints)
        .sort((a, b) => breakpoints[b] - breakpoints[a])
        .find(breakpoint => window.innerWidth >= breakpoints[breakpoint])

      if (widgetConfig.breakpoint !== currentBreakpoint) {
        const config = getTrustPilotWidgetBySize({
          size: currentBreakpoint,
          locale,
        })
        setWidgetConfig(config)
      }
    }

    updateWidgetOnScreenSize()

    window.addEventListener('resize', updateWidgetOnScreenSize)

    // Clean up the event listener on component unmounts
    return () => {
      window.removeEventListener('resize', updateWidgetOnScreenSize)
    }
  }, [widgetConfig])

  return (
    <Wrapper>
      {window.Trustpilot && (
        <div style={{ flex: 1 }} ref={ref} {...widgetConfig} />
      )}
    </Wrapper>
  )
}

const mapStateToProps = createStructuredSelector({
  locale: selectLocale,
})

export default connect(mapStateToProps, null)(TrustPilotRating)
