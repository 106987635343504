import * as C from './constants'

export const setWebView = payload => ({
  type: C.SET_WEBVIEW,
  payload,
})

export const logout = () => ({
  type: C.LOGOUT,
})

export const resetApp = () => ({
  type: C.RESET,
})

export const switchMeterRequest = payload => ({
  type: C.SWITCH_METER_REQUEST,
  payload,
})
export const switchMeterSuccess = payload => ({
  type: C.SWITCH_METER_SUCCESS,
  payload,
})
export const closeSwitchModal = () => ({
  type: C.CLOSE_SWITCH_MODAL,
})

export const getInAppMessagesSuccess = payload => ({
  type: C.IN_APP_MESSAGES_SUCCESS,
  payload,
})

export const showMessagesInbox = () => ({
  type: C.SHOW_MESSAGES_INBOX,
})

export const bannerExperimentDecided = payload => ({
  type: C.EXPERIMENT_DECIDED,
  payload,
})

export const renderingDashboard = () => ({
  type: C.RENDERING_DASHBOARD,
})
