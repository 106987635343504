import { merge } from 'ramda'
import { getStandardDisplayFormatFromString } from 'utils/dateUtils'
import {
  GET_RENEWAL_OFFER_REQUEST,
  RENEWAL_OFFER_SUCCESS,
  RENEWAL_OFFER_FAILURE,
  SELECT_OFFER,
  CONFIRM_RENEWAL_OFFER_SUCCESS,
  CONFIRM_RENEWAL_OFFER_FAILURE,
} from './constants'

const ENROLLMENT_TYPE_RENEWAL = 'renewal'
export const initialState = {
  error: '',
  loading: false,
  id: '',
  customerInfo: {},
  offers: [],
  offerExpiryDate: '',
  renewalDate: '',
  // indicates if offer is already availved with corrosponding enrollmentID
  renewalEnrollmentID: '',
  enrollment: {
    id: '',
    email: '',
    phone: '',
    enrollmentType: ENROLLMENT_TYPE_RENEWAL,
    createdBy: 'customer',
    formData: {
      firstName: '',
      lastName: '',
      meterUID: '',
      memberID: '',
      userID: '',
      product: {},
      address: {}, // service address
      billingAddress: {},
      requestedStartDate: '', // used for display purpose
    },
  },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RENEWAL_OFFER_REQUEST:
      return merge(state, {
        loading: true,
      })
    case RENEWAL_OFFER_SUCCESS: {
      const { customerInfo, renewalDate, id, offerExpiryDate } = action.payload
      const { user, member, meter } = customerInfo
      const { firstName, lastName, email, phone, billingAddress, userID } = user
      const { memberID } = member
      const { meterUID, serviceAddress } = meter
      return merge(state, {
        ...action.payload,
        loading: false,
        error: '',
        offerExpiryDate,
        enrollment: {
          id: '',
          email,
          phone,
          enrollmentType: ENROLLMENT_TYPE_RENEWAL,
          createdBy: 'customer',
          formData: {
            firstName,
            lastName,
            meterUID,
            memberID,
            userID,
            product: {},
            address: serviceAddress, // service address
            billingAddress,
            requestedStartDate: getStandardDisplayFormatFromString(renewalDate), // used for display purpose
            renewalOfferID: id,
          },
        },
      })
    }

    case SELECT_OFFER: {
      return merge(state, {
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            product: action.payload,
          },
        },
      })
    }

    case CONFIRM_RENEWAL_OFFER_SUCCESS: {
      const { enrollmentID } = action.payload
      return merge(state, {
        error: '',
        enrollment: {
          ...state.enrollment,
          id: enrollmentID,
        },
        renewalEnrollmentID: enrollmentID,
      })
    }

    case CONFIRM_RENEWAL_OFFER_FAILURE:
    case RENEWAL_OFFER_FAILURE:
      return merge(state, { error: action.payload, loading: false })

    default:
      return state
  }
}

export default reducer
