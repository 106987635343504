import React from 'react'
import PropTypes from 'prop-types'
import Media from 'react-media'
import styled from 'styled-components'
import { companyInfo, color } from 'config/profile'

import { breakpoints } from 'styles/mq'

const Span = styled.span`
  white-space: nowrap;
  padding-top: 10px;
  color: ${color.footerTextColor};
  font-size: 24px;
  line-height: 28px;
`

const A = styled.a`
  white-space: nowrap;
  padding-top: 10px;
  color: ${color.footerTextColor};
  font-size: 24px;
  line-height: 28px;
`

const PhoneContact = ({ withDash = true }) => (
  <Media query={{ minWidth: breakpoints.tablet }}>
    {matches =>
      matches ? (
        <Span>{withDash ? companyInfo.phone : companyInfo.phone}</Span>
      ) : (
        <A href={`tel:${companyInfo.phone}`}>
          {withDash ? companyInfo.phone : companyInfo.phone}
        </A>
      )
    }
  </Media>
)

PhoneContact.propTypes = {
  withDash: PropTypes.bool,
}

export default PhoneContact
