import React from 'react'
import { Trans } from '@lingui/macro'
import logo from 'assets/images/logos/BranchEnergyLogo-black.svg'
import { PAYMENT_PROVIDERS } from 'config'

// This config is based off of think not think-stripe
export const companyInfo = {
  name: 'Branch Energy',
  phone: '833-502-2691',
  termsLink: 'https://branchenergy.com/terms',
  privacyPolicyLink: 'https://branchenergy.com/privacy-policy',
  homepageLink: 'https://branchenergy.com',
  iosAppLink: 'https://itunes.apple.com/us',
  androidAppLink: 'https://play.app.goo.gl/',
  faviconLink: 'branchEnergy.ico',
  forgotPasswordLink: 'https://portal.branchenergy.com/forgot-password',
  paymentProvider: PAYMENT_PROVIDERS.STRIPE,
  EFLLink: 'https://branchenergy.com',
  TOSYRACLink: 'https://branchenergy.com',
  customerPortalLink: 'https://portal.branchenergy.com/login',
  callingHours: <Trans>(Mon - Fri 9am - 6pm CST)</Trans>,
  customerPortalName: 'Energy Portal',
  footerCertificate:
    'Branch Energy | PUCT Certificate #10295',
  supportEmail: 'help@branchenergy.com',
  noOffersPage_deprecated: 'https://branchenergy.com',
  // general (NTX) enrollment
  thankYouLink: 'https://www.branchenergy.com/welcome',
  thankYouLinkCommunitySolar: 'https://www.branchenergy.com/welcome',
  confirmEmailPassword: true,
  emailCommsAgreementText: <Trans>
    By selecting next you agree to receive all contract documents(call us if
    you want your terms of service sent via U.S. Mail), and written
    communications from us electronically (including via text or email) as
    permitted by law.
  </Trans>,
  autopayConsentText: <Trans>
    By checking this box you consent to enroll in Autopay, which is consent to
    charges to your credit card on any date between the bill issuance date and
    due date on your statement. Please enter payment information below. You
    will be able to manage your payment methods in your Energy Portal.
  </Trans>

}

// Values from Brand Guidelines design spec
// In case we need it, the green from the spec was '#60D269' (currently unused)
const BRAND_SPEC_WHITE = '#FFFFFF'
const BRAND_SPEC_BLACK = '#000000'
const BRAND_SPEC_SLATE = '#888787'
const BRAND_SPEC_CONCRETE = '#C4C4C4'

// Values sampled from Brand Site
const BRAND_SITE_CONCRETE = '#f2f2f2'
const BRAND_SITE_MAIN_GREEN = '#31a03a'
const BRAND_SITE_DARK_GREEN = '#2e7c34'

const base = BRAND_SPEC_BLACK
const primary = BRAND_SITE_MAIN_GREEN
const secondary = BRAND_SPEC_BLACK // button text
const blue = BRAND_SITE_MAIN_GREEN
const gray = BRAND_SPEC_CONCRETE
const grayMedium = BRAND_SPEC_CONCRETE
const grey = BRAND_SPEC_SLATE
const green = BRAND_SITE_MAIN_GREEN
const greenPromo = BRAND_SITE_DARK_GREEN
const error = '#dd2c00'
const neutral1 = BRAND_SPEC_SLATE // offer title text
const neutral2 = BRAND_SPEC_BLACK // offer info text
const neutral3 = BRAND_SPEC_BLACK // slider text
const neutral4 = BRAND_SPEC_SLATE // offer table border
const neutral5 = BRAND_SPEC_WHITE // offer box text
const white = BRAND_SPEC_WHITE
const footerColor = BRAND_SPEC_BLACK
const footerTextColor = BRAND_SPEC_WHITE
const darkerPrimary = BRAND_SITE_DARK_GREEN // used for hover effect
const background = BRAND_SITE_CONCRETE
const bannerborderbottom = `${BRAND_SPEC_SLATE} 1px solid`
const ratePanel = BRAND_SPEC_BLACK
const rateText = BRAND_SPEC_WHITE

export const color = {
  background,
  base,
  primary,
  secondary,
  blue,
  gray,
  grayMedium,
  grey,
  green,
  greenPromo,
  error,
  gradient: `linear-gradient(90deg, ${primary}, ${BRAND_SPEC_BLACK})`,
  link: BRAND_SITE_MAIN_GREEN,
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  white,
  footerColor,
  footerTextColor,
  darkerPrimary,
  transparent: '#ffffff00',
  containerBackground: 'transparent',
  bannerColor: BRAND_SPEC_WHITE,
  bannerTextColor: secondary,
  bannerborderbottom,
  progressBarColor: BRAND_SITE_MAIN_GREEN,
  progressBarBackground: '#fff',
  buttonTextColor: '#fff',
  buttonColor: primary,
  slider: primary,
  dropdown: base,
  ratePanel,
  rateText,
  offerDetails: secondary,
  offerShortDescription: ratePanel,
  formField: BRAND_SPEC_SLATE,
  circleCheckBackground: primary,
  energyProductIconBackground: primary,
  energyProductIcon: BRAND_SPEC_BLACK,
  sliderThumbShadow: 'none'
}

export const assets = {}

export const logoConfig = {
  localPath: 'assets/images/logos/BranchEnergyLogo-black.png',
  url: '',
  width: 'auto',
  height: '85px',
  padding: '10px',
  backgroundColor: 'transparent',
  useLocal: true,
  logo,
  progressBarImage: {
    custom: true,
    name: 'think',
    height: '85px',
    width: 'auto',
    stepImageNames: {
      1: 'think-home',
      2: 'think-light',
      3: 'think-lock',
      4: 'think-card',
    },
    filter: 'grayscale(100%)',
  },
  header: {
    height: '100px',
    position: 'left',
  },
}

const baseFamily = "'Helvetica Neue', Helvetica, Arial, sans-serif"
const customFont = "Lausanne"
const customButtonFont = "FavoritMono"

export const font = {
  extraBold: '700',
  semiBold: '600',
  medium: '400',
  normal: '350',
  button: '500',
  baseFamily,
  customFont,
  customButtonFont,
  customButtonFontTransformations: 'uppercase',
  customFamily: `${customFont}, ${baseFamily}`,
  customButtonFontFamily: `${customButtonFont}, 'Courier New', Courier, monospace`,
}

export const fontFace = `
@font-face {
  font-family:  ${customFont};
  src: url('/fonts/lausanne/TWKLausanne-700.woff2') format('woff2'),
    url('/fonts/lausanne/TWKLausanne-700.woff') format('woff'),
    url('/fonts/lausanne/TWKLausanne-700.ttf') format('truetype');
  font-weight: 700;
};

@font-face {
  font-family: ${customFont};
  src: url('/fonts/lausanne/TWKLausanne-350.woff2') format('woff2'),
    url('/fonts/lausanne/TWKLausanne-350.woff') format('woff'),
    url('/fonts/lausanne/TWKLausanne-350.ttf') format('truetype');
  font-weight: 350;
};

@font-face {
  font-family:${font.customButtonFont};
  src:  url('/fonts/favorit-mono/ABCFavoritMono-Regular.woff2') format('woff2'),
    url('/fonts/favorit-mono/ABCFavoritMono-Regular.woff') format('woff'),
   url('/fonts/favorit-mono/ABCFavoritMono-Regular.otf') format('opentype');
};
`

export const features = {
  DISPLAY_REFERRAL_NAME: true,
  CALL_HEADER: false,
  CALL_FOOTER: true,
  CALL_FOOTER_PHONE: true,
  CALL_FOOTER_EMAIL: true,
  REQUIRE_FULL_SOCIAL:
    process.env.REACT_APP_REQUIRE_FULL_SOCIAL === 'true' ? true : false,

  BACKGROUND: false,
  LOCALE_HEADER: {
    props: {
      position: 'right',
      alignSelf: 'center',
      m: '0px 8px',
    },
  },
  V2_THEME: true,
}
