import React from 'react'
import { Trans } from '@lingui/macro'
import logo from 'assets/images/logos/volta.png'
import { PAYMENT_PROVIDERS } from 'config'

export const companyInfo = {
  name: 'Voltus',
  phone: '888-925-1061',
  termsLink: 'https://abacusenergy.com/terms/',
  privacyPolicyLink: 'https://abacusenergy.com/privacy-policy/ ',
  homepageLink: 'https://www.voltus.co/',
  iosAppLink: 'https://itunes.apple.com/us',
  androidAppLink: 'https://play.app.goo.gl/',
  faviconLink: 'https://enroll.abacusenergy.com/voltus.ico',
  forgotPasswordLink: 'https://myabacus.abacusenergy.com/forgot-password',
  chaseMerchantID: '441193',
  chaseSubscriberID: '100000000325',
  chasePaymentCssURL:
    'https://frontend-chasepaymenttech.s3.us-west-2.amazonaws.com/form.css',
  paymentProvider: PAYMENT_PROVIDERS.CHASE,
  EFLLink: 'https://abacusenergy.com/terms/',
  TOSYRACLink: 'https://abacusenergy.com/terms/',
  customerPortalLink: 'https://development.d2nvicbndw5oub.amplifyapp.com',
  callingHours: <Trans>(Mon - Fri 8am - 5pm CST)</Trans>,
  customerPortalName: 'MyVoltus Portal',
  footerCertificate: '',
  supportEmail: '',

  // general (NTX) enrollment
  thankYouLink: '',
  thankYouLinkCommunitySolar:'',
}

const base = '#00263A' // '#022135'
const primary = '#84BD00' // '#84BD00'
const secondary = '#0076A8' // '#00A9E0'
const blue = '#00BCD4'
const gray = '#d5dee5'
const grayMedium = '#7D8D9E'
const grey = '#999999'
const green = '#7CA51B'
const greenPromo = '#8FAB36'
const error = '#dd2c00'
const neutral1 = '#022135'
const neutral2 = '#7D8D9E'
const neutral3 = '#9DB1C6'
const neutral4 = '#D5DEE5'
const neutral5 = '#F2F3F6'
const white = '#fff'
const footerColor = '#00263A'
const footerTextColor = '#fff'
const darkerPrimary = '#648d04' // used for hover effect

export const color = {
  background: '#f2f3f6',
  base,
  primary,
  secondary,
  blue,
  gray,
  grayMedium,
  grey,
  green,
  greenPromo,
  error,
  gradient: `linear-gradient(90deg, ${primary}, #f47725)`,
  link: primary,
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  white,
  footerColor,
  footerTextColor,
  darkerPrimary,
  transparent: '#ffffff00',
  containerBackground: '#fdfbf8',
  bannerColor: '#fff',
  bannerTextColor: secondary,
  progressBarColor: primary,
  progressBarBackground: 'transparent',
  buttonTextColor: '#fff',
  buttonColor: primary,
  slider: primary,
  dropdown: base,
  ratePanel: secondary,
  rateText: primary,
  offerDetails: neutral3,
  offerShortDescription: neutral2,
}

export const assets = {}

export const logoConfig = {
  localPath: 'assets/images/logos/volta.png',
  url: '',
  width: 'auto',
  height: '61px',
  backgroundColor: 'transparent',
  useLocal: true,
  logo, // this is used in header
  progressBarImage: {
    custom: true,
    height: '125px',
    width: '125px',
    stepImageNames: {
      1: 'voltus_circle',
      2: 'voltus_circle',
      3: 'voltus_circle',
      4: 'voltus_circle',
    },
  },
}

const baseFamily = "'Helvetica Neue', Helvetica, Arial, sans-serif"
const customFont = 'MADE Evolve Sans'

export const font = {
  extraBold: '800',
  semiBold: '600',
  medium: '500',
  normal: '400',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
}

export const fontFace = `
@font-face {
    font-family: ${font.customFont};
    src: url('/fonts/made-evolve-sans/MADE-Evolve-Sans-Regular.woff') format('woff');
    font-weight: ${font.normal};
    font-style: normal;
  }

  @font-face {
    font-family: ${font.customFont};
    src: url('/fonts/made-evolve-sans/MADE-Evolve-Sans-Medium.woff') format('woff');
    font-weight: ${font.medium};
    font-style: normal;
  }

  @font-face {
    font-family: ${font.customFont};
    src: url('/fonts/made-evolve-sans/MADE-Evolve-Sans-Bold.woff') format('woff');
    font-weight: ${font.semiBold};
    font-style: normal;
  }

  @font-face {
    font-family: ${font.customFont};
    src: url('/fonts/made-evolve-sans/MADE-Evolve-Sans-Bold.woff') format('woff');
    font-weight: ${font.extraBold};
    font-style: normal;
  }
`

export const features = {
  DISPLAY_REFERRAL_NAME: false,
  LOCALE_HEADER: true,
  CALL_HEADER: false,
  CALL_FOOTER: true,
  CALL_FOOTER_PHONE: true,
  CALL_FOOTER_EMAIL: true,
  REQUIRE_FULL_SOCIAL:
    process.env.REACT_APP_REQUIRE_FULL_SOCIAL === 'true' ? true : false,
}
