import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { Switch, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { parse } from 'query-string'

import SignUpPage from 'pages/SignUp/Loadable'
import MaintenancePage from 'pages/Maintenance/Loadable'
import { Root } from './styled'

import { selectIsWebView } from 'modules/app/selectors'

import * as actions from 'modules/app/actions'

import injectSaga from 'utils/injectSaga'
import saga from './saga'
import Header from './Header'
import Footer from './Footer'
import { isMobileWebview } from 'utils/utils'

export class App extends Component {
  mainContainer = React.createRef()

  componentWillMount() {
    const { location, isWebView, setWebView } = this.props
    const { client } = parse(location.search)
    const webViewClients = ['iOS', 'Android']
    if (!isWebView && webViewClients.includes(client)) {
      setWebView(client)
    } else if (!isWebView && isMobileWebview) {
      setWebView('mobileWebview')
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.mainContainer.current) {
        this.mainContainer.current.scrollTo(0, 0)
      }
    }
  }

  render() {
    return (
      <Root>
        {!isMobileWebview && <Header />}
        {process.env.REACT_APP_MAINTENANCE_MODE === 'true' && (
          <MaintenancePage />
        )}
        {process.env.REACT_APP_MAINTENANCE_MODE !== 'true' && (
          <Switch>
            <Route path="/" component={SignUpPage} />
          </Switch>
        )}
        {!isMobileWebview && <Footer />}
      </Root>
    )
  }
}

App.propTypes = {
  location: PropTypes.object.isRequired,
  isWebView: PropTypes.bool.isRequired,
  setWebView: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  isWebView: selectIsWebView,
})

export default compose(
  injectSaga({ key: 'app', saga }),
  withRouter,
  connect(mapStateToProps, {
    setWebView: actions.setWebView,
  })
)(App)
