import styled, { css } from 'styled-components'
import {
  fontSize,
  space,
  color,
  textAlign,
  flex,
  alignSelf,
  lineHeight,
  width,
  maxWidth,
  fontWeight,
  display,
} from 'styled-system'

import { color as colors, font } from 'styles/variables'
import mq from 'styles/mq'

import { linkStyles } from 'components/buttons/LinkButton/styled'

const defaultFontSize = (props) => {
  if (props.large) return font.sizes?.large || '1.8rem'
  if (props.small) return font.sizes?.small || '1.4rem'
  if (props.extraSmall) return font.sizes?.extraSmall || '1.2rem'
  return font.sizes?.default || '1.6rem'
}

const titleFont = font.customTitleFamily || font.customFamily

export const Text = styled.div`
  font-family: ${font.customFamily};
  font-size: ${defaultFontSize};
  color: ${colors.base};
  font-weight: ${(props) => (props.medium ? font.medium : font.normal)};
  ${(props) =>
    props.ellipsis &&
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    `};
  ${fontSize};
  ${space};
  ${color};
  ${textAlign};
  ${flex};
  ${alignSelf};
  ${lineHeight};
  ${width};
  ${maxWidth};
  ${fontWeight};
  ${display};

  ${(props) => (props.underline ? 'text-decoration: underline;' : '')}
  & strong {
    font-weight: ${font.semiBold};
  }

  & a {
    ${linkStyles};
    ${(props) => props.theme?.linkStyles?.inline};
  }
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      &,
      &::after {
        border-color: ${color.neutral4};
        background: #fff;
      }
    `}
`

export const TextHighlight = styled(Text)`
  display: inline-block;
  color: ${colors.blue};
  font-weight: ${font.medium};
`

export const TextBold = styled(Text)`
  font-weight: ${font.extraBold};
  font-size: 1.8rem;
  ${fontSize};
`

export const TextSemi = styled(Text)`
  font-weight: ${font.semiBold};
  font-size: 1.8rem;
  ${fontSize};
  ${space};
`

export const Heading = styled(Text)`
  font-family: ${titleFont};
  font-weight: ${font.heading || font.medium};
  font-size: ${font.sizes?.heading?.phone || '2.2rem'};
  ${mq.tablet`
    font-size: ${font.sizes?.heading?.tablet || '2.5rem'};
  `};
  ${textAlign}
`

export const PageTitle = styled(Text)`
  font-family: ${titleFont};
  font-weight: ${font.pageTitle || font.medium};
  font-size: ${font.sizes?.heading?.phone || '3rem'};
  ${mq.tablet`
    font-size: ${font.sizes?.pageTitle?.tablet || '3.5rem'}
  `};
  text-align: center;
  background: ${colors.transparent};
`

export const HeadingBold = styled(TextBold)`
  font-family: ${titleFont};
  font-size: 2.4rem;
`

export const TextSecondary = styled(Text)`
  color: ${colors.neutral3};
  font-weight: ${font.normal};
`
//  text-decoration-thickness: 2px;

export const TextLineThrough = styled(Text)`
  position: relative;
  text-decoration: line-through;
  text-decoration-color: ${colors.primary};
  ${`text-decoration-thickness: 2px;`};
`

export const TextBanner = styled(Text)`
  border: 1px solid ${colors.error};
  padding: 15px;
  margin-bottom: 50px;
  box-shadow: 1px 2px 2px 1px lightgrey;
  border-radius: 10px;
`

export const TextSecondarySummary = styled(Text)`
  color: ${colors.neutral3};
  font-weight: ${font.normal};
  margin-top: 4px;
`
